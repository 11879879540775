import { useState, useEffect, useRef, forwardRef, useCallback } from 'react';
import axios from 'axios';
import MainLayout from "../layout/mainLayout";
import Tooltip from '@mui/material/Tooltip';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import InsertPhotoOutlinedIcon from '@mui/icons-material/InsertPhotoOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Drawer from '@mui/material/Drawer';
import CircularProgress from '@mui/material/CircularProgress';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { Editor } from '@tinymce/tinymce-react';
import dayjs from 'dayjs';
import 'dayjs/locale/en-gb';

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const flattenCategories = (categories) => {
    return categories.reduce((acc, category) => {
        if (category.children.length > 0) {
            acc.push(category, ...category.children);
        } else {
            acc.push(category);
        }
        return acc;
    }, []);
};

export default function TempProducts() {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const page = useRef(1);
    const search = useRef(null);
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertText, setAlertText] = useState({
        msg: '',
        type: 'error'
    });
    const searchText = useRef('');
    const tableRef = useRef(null);
    const [categories, setCategories] = useState([]);
    const [tags, setTags] = useState([]);
    const [operatingSystem, setOperatingSystem] = useState([]);
    const [brands, setBrands] = useState([]);
    const [loadingUpdate, setLoadingUpdate] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const [categoriesForFilter, setCategoriesForFilter] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);
    const [selectedOperatingSystems, setSelectedOperatingSystems] = useState([]);
    const [selectedBrands, setSelectedBrands] = useState([]);
    const observer = useRef();

    const buildFilterUrl = () => {
        let url = `${process.env.REACT_APP_SHOP_API_URL}/app-admin/products/${page.current}?search=${searchText.current}`;

        selectedCategories.forEach(category => {
            url += `&categories[]=${category.id}`;
        });

        selectedTags.forEach(tag => {
            url += `&tags[]=${tag.id}`;
        });

        selectedOperatingSystems.forEach(os => {
            url += `&operating_systems[]=${os.id}`;
        });

        selectedBrands.forEach(brand => {
            url += `&brands[]=${brand.id}`;
        });

        return url;
    };

    const loadFiles = (search = '') => {
        setLoading(true);
        axios({
            method: 'get',
            url: buildFilterUrl(),
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            }
        }).then(function (response) {
            if (response.status === 200) {
                setData(prevData => [...prevData, ...response.data.files]);
                setHasMore(response.data.files.length > 0);
                setLoading(false);
            }
        }).catch(function (error) {
            setAlertOpen(true);
            setAlertText({
                msg: 'ไม่สามารถดึงข้อมูลได้',
                type: 'error'
            });
        });
    }
    const loadFilesRef = useRef(loadFiles);
    loadFilesRef.current = loadFiles;

    const lastFileElementRef = useCallback(node => {
        if (loading) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMore) {
                page.current++;
                loadFilesRef.current();
            }
        })
        if (node) observer.current.observe(node);
    }, [loading, hasMore]);

    useEffect(() => {
        document.title = `สินค้า - ${process.env.REACT_APP_SITE_NAME}`;
    }, []);

    useEffect(() => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_SHOP_API_URL}/app-admin/categories/1`,
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            }
        }).then(function (response) {
            if (response.status === 200) {
                setCategories(response.data.categories);
                const flatCategories = flattenCategories(response.data.categories);
                setCategoriesForFilter(flatCategories);
            }
        }).catch(function (error) {
            setAlertOpen(true);
            setAlertText({
                msg: 'ไม่สามารถดึงข้อมูลหมวดหมู่ได้',
                type: 'error'
            });
        });

        axios({
            method: 'get',
            url: `${process.env.REACT_APP_SHOP_API_URL}/app-admin/tags`,
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            }
        }).then(function (response) {
            if (response.status === 200) {
                setTags(response.data.tags);
            }
        }).catch(function (error) {
            setAlertOpen(true);
            setAlertText({
                msg: 'ไม่สามารถดึงข้อมูลหมวดหมู่ได้',
                type: 'error'
            });
        });

        axios({
            method: 'get',
            url: `${process.env.REACT_APP_SHOP_API_URL}/app-admin/operating-systems`,
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            }
        }).then(function (response) {
            if (response.status === 200) {
                setOperatingSystem(response.data.os);
            }
        }).catch(function (error) {
            setAlertOpen(true);
            setAlertText({
                msg: 'ไม่สามารถดึงข้อมูลระบบปฏิบัติการได้',
                type: 'error'
            });
        });

        axios({
            method: 'get',
            url: `${process.env.REACT_APP_SHOP_API_URL}/app-admin/brands`,
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            }
        }).then(function (response) {
            if (response.status === 200) {
                setBrands(response.data.brands);
            }
        }).catch(function (error) {
            setAlertOpen(true);
            setAlertText({
                msg: 'ไม่สามารถดึงข้อมูลแบรนด์ได้',
                type: 'error'
            });
        });


    }, []);

    useEffect(() => {
        if (selectedCategories.length > 0 || selectedTags.length > 0 || selectedOperatingSystems.length > 0 || selectedBrands.length > 0) {
            setData([]);
            page.current = 1;
            loadFilesRef.current();
        }
        if (selectedCategories.length === 0 && selectedTags.length === 0 && selectedOperatingSystems.length === 0 && selectedBrands.length === 0) {
            setData([]);
            page.current = 1;
            loadFilesRef.current();
        }
    }, [selectedCategories, selectedTags, selectedOperatingSystems, selectedBrands]);

    const submitSearch = (e) => {
        e.preventDefault();
        const inputValue = search.current.elements["top-search"].value;
        if (inputValue.trim() === "") {
            searchText.current = '';
            setData([]);
            page.current = 1;
            loadFiles();
            return;
        }
        searchText.current = inputValue;
        setData([]);
        page.current = 1;
        loadFiles(inputValue);
    }

    const [open, setOpen] = useState(false);
    const [editData, setEditData] = useState({
        id: null,
        slug: null
    });

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setEditData({ id: null, slug: null });
        setOpen(false);
        setDeleteId(null);
    };

    const handleSave = () => {

        if (singleProductData.name === '') {
            setAlertOpen(true);
            setAlertText({
                msg: 'กรุณากรอกชื่อสินค้า',
                type: 'error'
            });
            return;
        }

        if (singleProductData.images.length === 0) {
            setAlertOpen(true);
            setAlertText({
                msg: 'กรุณาอัพโหลดรูปภาพสินค้าอย่างน้อย 1 รูป',
                type: 'error'
            });
            return;
        }

        if (singleProductData.categories.length === 0) {
            setAlertOpen(true);
            setAlertText({
                msg: 'กรุณาเลือกหมวดหมู่สินค้าอย่างน้อย 1 หมวดหมู่',
                type: 'error'
            });
            return;
        }

        setLoadingUpdate(true);

        let url = `${process.env.REACT_APP_SHOP_API_URL}/app-admin/products`;
        let method = 'post';
        if (editData.id !== null) {
            url = `${process.env.REACT_APP_SHOP_API_URL}/app-admin/products/${editData.id}`;
            method = 'put';
        }

        axios({
            method: method,
            url: url,
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            },
            data: singleProductData
        }).then(function (response) {

            setAlertOpen(true);
            setAlertText({
                msg: 'บันทึกสำเร็จ',
                type: 'success'
            });
            resetSingleProductData();
            setDrawerOpen(false);

            if (editData.id === null) {
                page.current = 1;
                setData([]);
                loadFiles();
            } else {
                setData(prevData => {
                    return prevData.map(item => {
                        if (item.id === editData.id) {
                            return response.data.product;
                        }
                        return item;
                    })
                })
            }

            setLoadingUpdate(false);

        }).catch(function (error) {
            if (error.response && error.response.data.errors) {
                const errors = error.response.data.errors;
                const errorMessages = Object.keys(errors).map(key => `${key}: ${errors[key].join(', ')}`).join('\n');
                setAlertOpen(true);
                setAlertText({
                    msg: `บันทึกไม่สำเร็จ:\n${errorMessages}`,
                    type: 'error'
                });
            }
            setLoadingUpdate(false);
        });

    }

    const [drawerOpen, setDrawerOpen] = useState(false);

    const editorRef = useRef(null);
    const editorRef2 = useRef(null);
    const [singleProductData, setSingleProductData] = useState({
        name: '',
        description: '',
        regular_price: '0.00',
        sale_price: '0.00',
        images: [],
        categories: [],
        tags: [],
        downloads: [],
        operating_systems: [],
        short_description: '',
        product_video: '',
        date_created: new Date().toISOString(),
        brands: []
    });
    const resetSingleProductData = () => {
        setSingleProductData({
            name: '',
            description: '',
            regular_price: '0.00',
            sale_price: '0.00',
            images: [],
            categories: [],
            tags: [],
            downloads: [],
            operating_systems: [],
            short_description: '',
            product_video: '',
            date_created: new Date().toISOString(),
            brands: []
        });
        setEditData({ id: null, slug: null });
    }

    const fileInputRef = useRef();

    const onParentDivClick = () => {
        fileInputRef.current.click();
    }

    const [isLoading, setIsLoading] = useState(false);
    const [dragOver, setDragOver] = useState(false);

    const uploadImage = (files) => {
        setIsLoading(true);
        const fileReader = new FileReader();
        fileReader.onload = (event) => {
            const img = new Image();
            img.onload = () => {
                const width = img.width;
                const height = img.height;
                const formData = new FormData();
                formData.append('image', files[0]);
                axios({
                    method: 'post',
                    url: `${process.env.REACT_APP_CDN_URL}/howuetrpobxlvkjbsdoguqepfgaf`,
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('token'),
                        'Content-Type': 'multipart/form-data'
                    },
                    data: formData
                }).then(function (response) {
                    if (response.status === 200) {
                        setSingleProductData(prevData => {
                            return {
                                ...prevData,
                                images: [
                                    ...prevData.images,
                                    {
                                        src: response.data.filename,
                                        width: width,
                                        height: height
                                    }
                                ]
                            }
                        })
                        setIsLoading(false);
                    }
                }).catch(function (error) {
                    setAlertOpen(true);
                    setAlertText({
                        msg: 'อัพโหลดไม่สำเร็จ',
                        type: 'error'
                    });
                    setIsLoading(false);
                });
            }
            img.src = event.target.result;
        }
        fileReader.readAsDataURL(files[0]);
    }

    const [searchTerm, setSearchTerm] = useState('');

    const handleDelete = () => {

        axios({
            method: 'delete',
            url: `${process.env.REACT_APP_SHOP_API_URL}/app-admin/products/${deleteId.id}`,
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            }
        }).then(function (response) {
            if (response.status === 200) {
                setData(prevData => prevData.filter(item => item.id !== deleteId.id));
                setAlertOpen(true);
                setAlertText({
                    msg: 'ลบสำเร็จ',
                    type: 'success'
                });
                setOpen(false);
            }
        }).catch(function (error) {
            setAlertOpen(true);
            setAlertText({
                msg: 'ลบไม่สำเร็จ',
                type: 'error'
            });
        });
    }

    const HtmlLabel = ({ html }) => <span dangerouslySetInnerHTML={{ __html: html }} />;

    return (
        <>
            <MainLayout
                removePadding={true}
                pageContent={
                    <div id="real-main" className='flex flex-col justify-between px-6' ref={tableRef}>
                        <div className='flex justify-between items-end mt-4 mb-2'>
                            <div className='flex gap-4'>
                                <h1 className='font-bold text-xl'>สินค้า</h1>

                                <div style={{ width: '320px' }} className='max-w-full'>
                                    <Autocomplete
                                        multiple
                                        options={categoriesForFilter}
                                        getOptionLabel={(option) => option.name}
                                        value={selectedCategories}
                                        onChange={(e, value) => {
                                            setSelectedCategories(value);
                                        }}
                                        renderOption={(props, option, { selected }) => (
                                            <li {...props}>
                                                <span style={{ marginLeft: option.cat_parent ? 20 : 0 }}>
                                                    <Checkbox checked={selected} size="small" />
                                                    <span dangerouslySetInnerHTML={{ __html: option.name }}></span>
                                                </span>
                                            </li>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="standard"
                                                placeholder="เลือกหมวดหมู่"
                                            />
                                        )}
                                        renderTags={(value, getTagProps) =>
                                            value.map((option, index) => (
                                                <Chip
                                                    variant="outlined"
                                                    label={<HtmlLabel html={option.name} />}
                                                    {...getTagProps({ index })}
                                                    size="small"
                                                />
                                            ))
                                        }
                                    />
                                </div>
                                <div style={{ width: '250px' }} className='max-w-full'>
                                    <Autocomplete
                                        multiple
                                        options={tags}
                                        getOptionLabel={(option) => option.name}
                                        value={selectedTags}
                                        onChange={(e, value) => {
                                            setSelectedTags(value);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="standard"
                                                placeholder="เลือกแท็ก"
                                            />
                                        )}
                                        renderTags={(value, getTagProps) =>
                                            value.map((option, index) => (
                                                <Chip variant="outlined" label={option.name} {...getTagProps({ index })} size="small" />
                                            ))
                                        }
                                    />
                                </div>
                                <div style={{ width: '200px' }} className='max-w-full'>
                                    <Autocomplete
                                        multiple
                                        options={operatingSystem}
                                        getOptionLabel={(option) => option.name}
                                        value={selectedOperatingSystems}
                                        onChange={(e, value) => {
                                            setSelectedOperatingSystems(value);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="standard"
                                                placeholder="เลือกระบบปฏิบัติการ"
                                            />
                                        )}
                                        renderTags={(value, getTagProps) =>
                                            value.map((option, index) => (
                                                <Chip variant="outlined" label={option.name} {...getTagProps({ index })} size="small" />
                                            ))
                                        }
                                    />
                                </div>
                                <div style={{ width: '320px' }} className='max-w-full'>
                                    <Autocomplete
                                        multiple
                                        options={brands}
                                        getOptionLabel={(option) => option.name}
                                        value={selectedBrands}
                                        onChange={(e, value) => {
                                            setSelectedBrands(value);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="standard"
                                                placeholder="เลือกแบรนด์"
                                            />
                                        )}
                                        renderTags={(value, getTagProps) =>
                                            value.map((option, index) => (
                                                <Chip variant="outlined" label={option.name} {...getTagProps({ index })} size="small" />
                                            ))
                                        }
                                    />
                                </div>

                            </div>
                            <div className='flex gap-4'>

                                <Button
                                    variant='contained'
                                    onClick={() => {
                                        setDrawerOpen(true);
                                    }}
                                >
                                    + เพิ่มสินค้า
                                </Button>
                            </div>
                        </div>
                        <div className='table-responsive mt-2 bg-white'>
                            <table className="table-auto w-full border-collapse border border-slate-150">
                                <thead>
                                    <tr>
                                        <th className='text-left border-b border-slate-150 p-4 pl-8 pt-3 pb-3 font-bold text-noto'>ชื่อ</th>
                                        <th className='text-left border-b border-slate-150 p-4 pl-8 pt-3 pb-3 font-bold text-noto'>แก้ไขล่าสุด</th>
                                        <th className='text-left border-b border-slate-150 p-4 pl-8 pt-3 pb-3 font-bold text-noto'>ราคา</th>
                                        <th className='text-right border-b border-slate-150 p-4 pl-8 pr-6 pt-3 pb-3 font-bold text-noto'></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.length > 0 ? Object.entries(data).map(([k, item], index) => (
                                        <tr className='align-center border-b border-b-slate-150 hover:bg-slate-50' key={k} ref={index === data.length - 1 ? lastFileElementRef : null}>
                                            <td className='p-4 py-1 pl-8'>
                                                <h4 className=''>{item.name}</h4>
                                            </td>
                                            <td className='p-4 pl-8'>
                                                <p>{
                                                    new Date(item.date_modified).toLocaleString('th-TH', {
                                                        year: 'numeric',
                                                        month: 'long',
                                                        day: 'numeric',
                                                        hour: 'numeric',
                                                        minute: 'numeric'
                                                    })
                                                }</p>
                                            </td>
                                            <td className='p-4 pl-8'>
                                                <p>{item.regular_price} | {item.sale_price}</p>
                                            </td>
                                            <td className='p-4 pl-8 text-right'>
                                                <div className='flex gap-2 justify-end'>
                                                    <Tooltip title={<span style={{ fontSize: '16px' }}>แก้ไข</span>} placement="top" arrow>
                                                        <button
                                                            className='rounded-full flex justify-center items-center w-8 h-8 bg-slate-100'
                                                            onClick={() => {
                                                                setEditData({
                                                                    id: item.id,
                                                                    slug: item.slug
                                                                });
                                                                setSingleProductData(prevData => {
                                                                    return {
                                                                        ...prevData,
                                                                        name: item.name,
                                                                        description: item.description,
                                                                        regular_price: item.regular_price,
                                                                        sale_price: item.sale_price,
                                                                        images: [...prevData.images, ...(item.images || []), {
                                                                            src: item.main_image,
                                                                            width: 400,
                                                                            height: 225
                                                                        }],
                                                                        categories: item.categories.map(category => category.id),
                                                                        tags: item.tags.map(tag => tag.id),
                                                                        downloads: item.downloads,
                                                                        operating_systems: item.operating_systems.map(os => os.id),
                                                                        short_description: item.short_description,
                                                                        product_video: item.product_video,
                                                                        date_created: item.date_created,
                                                                        brands: item.brands.map(brand => brand.id)
                                                                    }
                                                                });
                                                                setDrawerOpen(true);
                                                            }}
                                                        >
                                                            <EditOutlinedIcon sx={{ fontSize: '16px' }} />
                                                        </button>
                                                    </Tooltip>

                                                    <Tooltip title={<span style={{ fontSize: '16px' }}>ลบ</span>} placement="top" arrow>
                                                        <button
                                                            className='rounded-full flex justify-center items-center w-8 h-8 bg-slate-100'
                                                            onClick={() => {
                                                                setDeleteId(item);
                                                                handleClickOpen();
                                                            }}
                                                        >
                                                            <DeleteForeverOutlinedIcon sx={{ fontSize: '16px' }} />
                                                        </button>
                                                    </Tooltip>
                                                </div>
                                            </td>
                                        </tr>
                                    )) : (
                                        <tr>
                                            <td colSpan={4} className='p-4 pl-8 text-center'>ไม่พบข้อมูล</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                            {loading && <div className='w-full flex justify-center p-4'><CircularProgress /></div>}
                            {!hasMore && <p className='text-center p-4 border border-t-0'>No more products to load</p>}
                        </div>
                    </div>
                }
                submitSearch={submitSearch}
                searchTextChange={search}
            />

            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={alertOpen} autoHideDuration={3000} onClose={() => setAlertOpen(false)}>
                <Alert onClose={() => setAlertOpen(false)} severity={alertText.type}>
                    {alertText.msg}
                </Alert>
            </Snackbar>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
            >
                <DialogTitle id="alert-dialog-title">
                    ยินยันการลบข้อมูลสินค้า
                </DialogTitle>
                <DialogContent>

                    สินค้า {deleteId?.name} จะถูกลบออกจากระบบ และไม่สามารถกู้คืนได้

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>
                        ยกเลิก
                    </Button>
                    <Button onClick={handleDelete} autoFocus>
                        ยืนยัน
                    </Button>
                </DialogActions>
            </Dialog>

            <Drawer
                anchor={'left'}
                open={drawerOpen}
                onClose={() => { setDrawerOpen(false); resetSingleProductData() }}

            >
                <div className='p-4 container max-w-full bg-slate-50' style={{ width: '95vw' }}>
                    <div className='flex justify-between items-center pb-2 border-b'>
                        <h1 className='font-bold text-xl'>{singleProductData.name === '' ? 'เพิ่ม' : 'แก้ไข'}สินค้า</h1>
                        <button onClick={() => { setDrawerOpen(false); resetSingleProductData() }}>
                            <CloseOutlinedIcon />
                        </button>
                    </div>

                    <div className='mt-4 grid grid-cols-12 gap-4'>
                        <div className='col-span-12 md:col-span-9'>
                            <input
                                type="text"
                                className='border border-slate-300 p-2 w-full mb-3 text-lg text-noto font-bold focus:outline-none mt-4 rounded-lg'
                                placeholder='ชื่อสินค้า'
                                value={singleProductData?.name}
                                onChange={(e) => {
                                    setSingleProductData(prevData => {
                                        return {
                                            ...prevData,
                                            name: e.target.value
                                        }
                                    })
                                }}
                            />

                            {editData.slug && (
                                <a href={`${process.env.REACT_APP_FRONTEND_URL}/product/${editData.slug}`} target='_blank' rel="noreferrer" className='underline mb-4 block ml-2'>
                                    {`${process.env.REACT_APP_FRONTEND_URL}/product/${editData.slug}`}
                                </a>
                            )}

                            <Editor
                                apiKey={process.env.REACT_APP_TINY_API_KEY}
                                onInit={(evt, editor) => editorRef.current = editor}
                                value={singleProductData?.description}
                                onEditorChange={(content) => {
                                    setSingleProductData(prevData => {
                                        return {
                                            ...prevData,
                                            description: content
                                        }
                                    })
                                }}
                                init={{
                                    height: 500,
                                    menubar: true,
                                    toolbar: 'undo redo |  | ' +
                                        'bold italic backcolor | alignleft aligncenter ' +
                                        'alignright alignjustify | bullist numlist outdent indent | ' +
                                        'removeformat | help' +
                                        'h1 h2 h3 h4 h5 h6 | image media link | table',
                                    plugins: ['lists', 'table', 'link', 'image', 'imagetools', 'media'],
                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                }}
                            />

                            <div className='border p-4 mt-4 rounded-lg bg-white'>
                                <label className='text-sm font-bold text-noto sticky top-0 block w-full bg-white border-b pb-2'>ราคาสินค้า</label>
                                <div className='grid gap-4 grid-cols-2 border p-4 mt-4 rounded-lg bg-white'>
                                    <div>
                                        <TextField
                                            label="ราคาปกติ"
                                            type="number"
                                            className='border border-slate-100 p-2 rounded-md w-full'
                                            placeholder='ราคาปกติ'
                                            variant="standard"
                                            InputProps={{
                                                endAdornment: <span>฿</span>,
                                            }}
                                            value={singleProductData?.regular_price}
                                            onChange={(e) => {
                                                setSingleProductData(prevData => {
                                                    return {
                                                        ...prevData,
                                                        regular_price: e.target.value
                                                    }
                                                })
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <TextField
                                            label="ราคาขาย"
                                            type="number"
                                            className='border border-slate-100 p-2 rounded-md w-full'
                                            placeholder='ราคาขาย'
                                            variant="standard"
                                            InputProps={{
                                                endAdornment: <span>฿</span>,
                                            }}
                                            value={singleProductData?.sale_price}
                                            onChange={(e) => {
                                                setSingleProductData(prevData => {
                                                    return {
                                                        ...prevData,
                                                        sale_price: e.target.value
                                                    }
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>




                            <div className='border p-4 mt-4 rounded-lg bg-white'>
                                <label className='text-sm font-bold text-noto sticky top-0 block w-full bg-white mb-4 border-b pb-2'>ดาวน์โหลด</label>
                                {singleProductData?.downloads?.map((item, index) => (
                                    <div key={index} className='flex gap-2 w-full items-center mb-2'>
                                        <label className='text-sm font-bold'>{index + 1}</label>
                                        <input
                                            type="text"
                                            className='border border-slate-300 p-2 w-full focus:outline-none rounded-lg'
                                            value={item.name}
                                            placeholder='ชื่อไฟล์'
                                            onChange={(e) => {
                                                setSingleProductData(prevData => {
                                                    return {
                                                        ...prevData,
                                                        downloads: prevData.downloads.map((d, i) => {
                                                            if (i === index) {
                                                                return {
                                                                    ...d,
                                                                    name: e.target.value
                                                                }
                                                            }
                                                            return d;
                                                        })
                                                    }
                                                })
                                            }}
                                        />
                                        <input
                                            type="text"
                                            className='border border-slate-300 p-2 w-full focus:outline-none rounded-lg' value={item.file}
                                            placeholder='e.g. https://www.example.com/file.zip'
                                            onChange={(e) => {
                                                setSingleProductData(prevData => {
                                                    return {
                                                        ...prevData,
                                                        downloads: prevData.downloads.map((d, i) => {
                                                            if (i === index) {
                                                                return {
                                                                    ...d,
                                                                    file: e.target.value
                                                                }
                                                            }
                                                            return d;
                                                        })
                                                    }
                                                })
                                            }}
                                        />
                                        <button className=''
                                            onClick={() => {
                                                setSingleProductData(prevData => {
                                                    return {
                                                        ...prevData,
                                                        downloads: prevData.downloads.filter((_, i) => i !== index)
                                                    }
                                                })
                                            }}
                                        >
                                            <CloseOutlinedIcon />
                                        </button>
                                    </div>
                                ))}
                                <button
                                    className='mt-4 bg-slate-100 rounded-md p-2'
                                    onClick={() => {
                                        setSingleProductData(prevData => {
                                            return {
                                                ...prevData,
                                                downloads: [
                                                    ...prevData.downloads,
                                                    {
                                                        name: '',
                                                        file: ''
                                                    }
                                                ]
                                            }
                                        })
                                    }}
                                >
                                    + เพิ่มลิ้งก์ดาวน์โหลด
                                </button>
                            </div>


                            <div className='border p-4 mt-4 rounded-lg bg-white'>
                                <label className='text-sm font-bold text-noto sticky top-0 block w-full bg-white mb-4 border-b pb-2'>รายละเอียดโดยย่อ</label>
                                <Editor
                                    apiKey={process.env.REACT_APP_TINY_API_KEY}
                                    onInit={(evt, editor) => editorRef2.current = editor}
                                    value={singleProductData?.short_description}
                                    onEditorChange={(content) => {
                                        setSingleProductData(prevData => {
                                            return {
                                                ...prevData,
                                                short_description: content
                                            }
                                        })
                                    }}
                                    init={{
                                        height: 400,
                                        menubar: true,
                                        toolbar: 'undo redo |  | ' +
                                            'bold italic backcolor | alignleft aligncenter ' +
                                            'alignright alignjustify | bullist numlist outdent indent | ' +
                                            'removeformat | help' +
                                            'h1 h2 h3 h4 h5 h6 | image media link | table',
                                        plugins: ['lists', 'table', 'link', 'image', 'imagetools', 'media'],
                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                    }}
                                />
                            </div>



                        </div>
                        <div className='col-span-12 md:col-span-3'>

                            <div className='border p-4 mt-4 rounded-lg bg-white'>
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                    <DateTimePicker
                                        label="วันที่เผยแพร่"
                                        value={dayjs(singleProductData?.date_created || new Date())}
                                        onChange={(newValue) => {
                                            setSingleProductData(prevData => {
                                                return {
                                                    ...prevData,
                                                    date_created: newValue
                                                }
                                            })
                                        }}
                                        inputFormat="DD/MM/YYYY HH:mm"
                                        slotProps={{ textField: { style: { width: '100%' } } }}

                                    />
                                </LocalizationProvider>

                                <Button variant="contained" sx={{ mt: 2 }} fullWidth
                                    onClick={handleSave}
                                    disabled={loadingUpdate}
                                >
                                    {editData.id ? 'แก้ไข' : 'เพิ่ม'}สินค้า
                                </Button>
                            </div>


                            <div className='border p-4 mt-4 rounded-lg bg-white'>
                                <label className='text-sm font-bold text-noto  block w-full bg-white border-b pb-2'>หมวดหมู่</label>
                                {categories.length > 0 && (
                                    <div style={{ maxHeight: '200px' }} className='overflow-auto'>
                                        <div className=''>
                                            <FormGroup>
                                                {categories.map((item, index) => (
                                                    <div key={index}>
                                                        <FormControlLabel
                                                            sx={{
                                                                '& .MuiFormControlLabel-label': {
                                                                    fontSize: '14px'
                                                                }
                                                            }}
                                                            control={
                                                                <Checkbox
                                                                    checked={singleProductData.categories.includes(item.id)}
                                                                    onChange={(e) => {
                                                                        if (e.target.checked) {
                                                                            setSingleProductData(prevData => {
                                                                                return {
                                                                                    ...prevData,
                                                                                    categories: [
                                                                                        ...prevData.categories,
                                                                                        item.id
                                                                                    ]
                                                                                }
                                                                            })
                                                                        } else {
                                                                            setSingleProductData(prevData => {
                                                                                return {
                                                                                    ...prevData,
                                                                                    categories: prevData.categories.filter(c => c !== item.id)
                                                                                }
                                                                            })
                                                                        }
                                                                    }}
                                                                    name={item.name}
                                                                    size="small"
                                                                />
                                                            }
                                                            label={<span dangerouslySetInnerHTML={{ __html: item.name }} />}
                                                        />
                                                        {
                                                            item.children.length > 0 && (
                                                                <div className='ml-4'>
                                                                    <FormGroup>
                                                                        {item.children.map((child, index) => (
                                                                            <FormControlLabel
                                                                                sx={{
                                                                                    '& .MuiFormControlLabel-label': {
                                                                                        fontSize: '14px'
                                                                                    }
                                                                                }}
                                                                                key={index}
                                                                                control={
                                                                                    <Checkbox
                                                                                        checked={singleProductData.categories.includes(child.id)}
                                                                                        onChange={(e) => {
                                                                                            if (e.target.checked) {
                                                                                                setSingleProductData(prevData => {
                                                                                                    return {
                                                                                                        ...prevData,
                                                                                                        categories: [
                                                                                                            ...prevData.categories,
                                                                                                            child.id
                                                                                                        ]
                                                                                                    }
                                                                                                })
                                                                                            } else {
                                                                                                setSingleProductData(prevData => {
                                                                                                    return {
                                                                                                        ...prevData,
                                                                                                        categories: prevData.categories.filter(c => c !== child.id)
                                                                                                    }
                                                                                                })
                                                                                            }
                                                                                        }}
                                                                                        name={child.name}
                                                                                        size="small"
                                                                                    />
                                                                                }
                                                                                label={child.name}
                                                                            />
                                                                        ))}
                                                                    </FormGroup>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                ))}
                                            </FormGroup>
                                        </div>
                                    </div>
                                )}
                            </div>


                            <div className='border p-4 mt-4 rounded-lg bg-white'>
                                <label className='text-sm font-bold text-noto sticky top-0 block w-full bg-white'>แท็ก</label>
                                <Autocomplete
                                    multiple
                                    options={tags}
                                    getOptionLabel={(option) => option.name}
                                    value={tags.filter(tag => singleProductData.tags.includes(tag.id))}
                                    onChange={(e, value) => {
                                        setSingleProductData(prevData => {
                                            return {
                                                ...prevData,
                                                tags: value.map(v => v.id)
                                            }
                                        })
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            placeholder="เลือกแท็ก"
                                        />
                                    )}
                                    renderTags={(value, getTagProps) =>
                                        value.map((option, index) => (
                                            <Chip variant="outlined" label={option.name} {...getTagProps({ index })} />
                                        ))
                                    }
                                />
                            </div>

                            <div className='border p-4 mt-4 rounded-lg bg-white'>
                                <label className='text-sm font-bold text-noto sticky top-0 block w-full bg-white mb-4 pb-2 border-b'>ระบบปฏิบัติการ</label>
                                {operatingSystem.length > 0 && (
                                    <div style={{ maxHeight: '300px' }} className='overflow-auto'>
                                        <div className=''>
                                            <FormGroup>
                                                {operatingSystem.map((item, index) => (
                                                    <div key={index}>
                                                        <FormControlLabel
                                                            sx={{
                                                                '& .MuiFormControlLabel-label': {
                                                                    fontSize: '14px'
                                                                }
                                                            }}
                                                            control={
                                                                <Checkbox
                                                                    checked={singleProductData.operating_systems.includes(item.id)}
                                                                    onChange={(e) => {
                                                                        if (e.target.checked) {
                                                                            setSingleProductData(prevData => {
                                                                                return {
                                                                                    ...prevData,
                                                                                    operating_systems: [
                                                                                        ...prevData.operating_systems,
                                                                                        item.id
                                                                                    ]
                                                                                }
                                                                            })
                                                                        } else {
                                                                            setSingleProductData(prevData => {
                                                                                return {
                                                                                    ...prevData,
                                                                                    operating_systems: prevData.operating_systems.filter(c => c !== item.id)
                                                                                }
                                                                            })
                                                                        }
                                                                    }}
                                                                    name={item.name}
                                                                    size="small"
                                                                />
                                                            }
                                                            label={item.name}
                                                        />
                                                    </div>
                                                ))}
                                            </FormGroup>
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div className='border p-4 mt-4 rounded-lg bg-white'>
                                <label className='text-sm font-bold text-noto sticky top-0 block w-full bg-white mb-4 pb-2 border-b'>ผู้พัฒนา</label>
                                <input
                                    type="text"
                                    placeholder="Search brands..."
                                    value={searchTerm}
                                    onChange={e => setSearchTerm(e.target.value)}
                                    className=' p-2 w-full focus:outline-none rounded-lg'
                                />
                                {brands.length > 0 && (
                                    <div style={{ maxHeight: '200px' }} className='overflow-auto'>
                                        <div className=''>
                                            <FormGroup>
                                                {brands.filter(item => item.name.toLowerCase().includes(searchTerm.toLowerCase())).map((item, index) => (
                                                    <div key={index}>
                                                        <FormControlLabel
                                                            sx={{
                                                                '& .MuiFormControlLabel-label': {
                                                                    fontSize: '14px'
                                                                }
                                                            }}
                                                            control={
                                                                <Checkbox
                                                                    checked={singleProductData.brands.includes(item.id)}
                                                                    onChange={(e) => {
                                                                        if (e.target.checked) {
                                                                            setSingleProductData(prevData => {
                                                                                return {
                                                                                    ...prevData,
                                                                                    brands: [
                                                                                        ...prevData.brands,
                                                                                        item.id
                                                                                    ]
                                                                                }
                                                                            })
                                                                        } else {
                                                                            setSingleProductData(prevData => {
                                                                                return {
                                                                                    ...prevData,
                                                                                    brands: prevData.brands.filter(c => c !== item.id)
                                                                                }
                                                                            })
                                                                        }
                                                                    }}
                                                                    name={item.name}
                                                                    size="small"
                                                                />
                                                            }
                                                            label={item.name}
                                                        />
                                                    </div>
                                                ))}
                                            </FormGroup>
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div className='border p-4 mt-4 rounded-lg bg-white'>
                                <label className='text-sm font-bold text-noto sticky top-0 block w-full bg-white mb-4  pb-2 border-b'>รูปภาพสินค้า</label>
                                <div className='mt-4 aspect-video rounded-lg bg-white cursor-pointer'
                                    onClick={onParentDivClick}
                                    onDragOver={(e) => {
                                        e.preventDefault();
                                        setDragOver(true);
                                    }}
                                    onDragLeave={() => {
                                        setDragOver(false);
                                    }}
                                    onDrop={(e) => {
                                        e.preventDefault();
                                        setDragOver(false);
                                        const files = e.dataTransfer.files;
                                        uploadImage(files);
                                    }}
                                >
                                    {isLoading ? (
                                        <div className='flex w-full h-full justify-center items-center border border-dashed bg-slate-50 rounded-lg'>
                                            <div className='text-center'>
                                                <CircularProgress />
                                                <div>Uploading...</div>
                                            </div>
                                        </div>
                                    ) : dragOver ? (
                                        <div className='flex w-full h-full justify-center items-center border border-dashed bg-slate-50 rounded-lg'>
                                            <div className='text-center opacity-50'>
                                                <InsertPhotoOutlinedIcon sx={{ fontSize: '48px' }} />
                                                <div>Drop here</div>
                                            </div>
                                        </div>
                                    ) : (
                                        singleProductData.images.length > 0 ? (
                                            <div className='rounded-lg'>
                                                {singleProductData.images.map((item, index) => (
                                                    <div key={index} className='relative'>
                                                        <img src={`${process.env.REACT_APP_CDN_URL}/image/${item.width}x${item.height}/webp/${item.src}`}
                                                            alt={item.src} className='rounded-lg w-full max-w-full h-full object-cover' />
                                                        <button
                                                            className='absolute top-2 right-2 bg-white/50 rounded-full w-8 h-8 flex justify-center items-center'
                                                            onClick={(event) => {
                                                                event.stopPropagation();
                                                                setSingleProductData(prevData => {
                                                                    return {
                                                                        ...prevData,
                                                                        images: prevData.images.filter((_, i) => i !== index)
                                                                    }
                                                                })
                                                            }}
                                                        >
                                                            <span className=''>
                                                                <CloseOutlinedIcon />
                                                            </span>
                                                        </button>
                                                    </div>
                                                ))}
                                            </div>
                                        ) : (
                                            <div className='flex flex-col justify-center items-center w-full h-full'>
                                                <p className='text-gray-500'>ลากไฟล์มาวางที่นี่ หรือคลิกเพื่ออัปโหลดรูป</p>
                                            </div>
                                        )
                                    )}
                                    <input
                                        type="file"
                                        ref={fileInputRef}
                                        className='hidden'
                                        onChange={(e) => {
                                            const files = e.target.files;
                                            uploadImage(files);
                                        }}
                                    />
                                </div>
                            </div>

                            <div className='border p-4 mt-4 rounded-lg bg-white'>
                                <label className='text-sm font-bold text-noto sticky top-0 block w-full bg-white pb-2 border-b'>วิดีโอ</label>
                                <input
                                    type="text"
                                    className='border border-slate-300 p-2 w-full focus:outline-none rounded-lg mt-4'
                                    placeholder='e.g. https://www.youtube.com/watch?v=xxxxxxxxxxx'
                                    value={singleProductData?.product_video}
                                    onChange={(e) => {
                                        setSingleProductData(prevData => {
                                            return {
                                                ...prevData,
                                                product_video: e.target.value
                                            }
                                        })
                                    }}
                                />
                            </div>


                        </div>
                    </div>
                </div>
            </Drawer>
        </>
    );
}