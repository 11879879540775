import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import MainLayout from "../layout/mainLayout";
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Pagination from '@mui/material/Pagination';

export default function Leads() {

    const [data, setData] = useState([]);
    const [open, setOpen] = useState(false);
    const [allPage, setAllPage] = useState(0);
    const page = useRef(0);
    const tableRef = useRef(null);

    useEffect(() => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}/leads?offset=${page.current}`,
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            }
        }).then(function (response) {
            if (response.data.status === 200) {
                setData(response.data.data);
                setAllPage(response.data.all);
            }
        });
        document.title = `ลูกค้า - ${process.env.REACT_APP_SITE_NAME}`;
    }, []);

    const handlePageChange = (event, value) => {
        page.current = value - 1;
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}/leads?offset=${page.current}`,
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            }
        }).then(function (response) {
            if (response.data.status === 200) {
                tableRef.current?.scrollIntoView({ behavior: 'smooth' });
                setData(response.data.data);
                setAllPage(response.data.all);
            }
        });
    }



    return (
        <>
            <MainLayout
                //pageTitle='รายชื่อผู้ติดต่อ'
                disableFrom={true}
                removePadding={true}
                pageContent={
                    <div id="real-main" className='flex flex-col justify-between px-6' ref={tableRef}>
                        <div className='flex justify-between items-center mt-4'>
                            <h1 className='font-bold text-xl'>ลูกค้า</h1>

                        </div>
                        <div className='table-responsive mt-2 bg-white'>
                            <table className="table-auto w-full border-collapse border border-slate-150">
                                <thead>
                                    <tr>
                                        <th className='text-left border-b border-slate-150 p-4 pl-8 pt-3 pb-3 font-bold text-noto'>โปรไฟล์</th>
                                        <th className='text-left border-b border-slate-150 p-4 pl-8 pt-3 pb-3 font-bold text-noto'>ชื่อโปรไฟล์</th>
                                        <th className='text-left border-b border-slate-150 p-4 pl-8 pt-3 pb-3 font-bold text-noto'>แหล่งที่มา</th>
                                        <th className='text-left border-b border-slate-150 p-4 pl-8 pt-3 pb-3 font-bold text-noto'>อัปเดตล่าสุด</th>
                                        <th className='text-right border-b border-slate-150 p-4 pl-8 pr-6 pt-3 pb-3 font-bold text-noto'>จัดการ</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Object.entries(data).map(([k, item]) => (
                                        <tr className='align-baseline border-b border-b-slate-150' key={k}>
                                            <td className='p-4 py-1 pl-8'>
                                                <Badge
                                                    overlap="circular"
                                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                    badgeContent={item.source === 1 ?
                                                        <Avatar alt='Line icon' sx={{ width: 18, height: 18, bgcolor: '#00B900' }}></Avatar> :
                                                        <Avatar alt='facebook icon' sx={{ width: 18, height: 18, bgcolor: '#0a7cff' }}></Avatar>
                                                    }
                                                >
                                                    <Avatar
                                                        alt={item.name}
                                                        src={item.source === 1 ? item.avatar : `${process.env.REACT_APP_BACKEND_URL}/image/${item.avatar}/45`}
                                                        sx={{ width: 45, height: 45 }}
                                                    />
                                                </Badge>
                                            </td>
                                            <td className='p-4 pl-8'>{item.name}</td>
                                            <td className='p-4 pl-8'>
                                                <Chip label={item.source === 1 ? 'Line' : 'Facebook'} variant="outlined" />
                                            </td>
                                            <td className='p-4 pl-8'>
                                                {item.date}
                                            </td>
                                            <td className='p-4 pl-8 text-right'>
                                                <div className='flex gap-4 justify-end'>

                                                    <Button
                                                        onClick={() => setOpen(true)}
                                                        variant="outlined">บล็อก</Button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>

                        <div className='flex p-4 justify-between items-center sticky bottom-0 bg-slate-50 z-50'>
                            <div className='text-noto'>
                                หน้าที่ {page.current + 1} / {allPage}
                            </div>
                            <Pagination count={allPage} page={page.current + 1} onChange={handlePageChange} />
                        </div>
                    </div>
                }
            />

            <Dialog
                fullWidth={true}
                open={open}
                onClose={() => setOpen(false)}
                maxWidth={'sm'}
            >
                <DialogTitle>ยืนยันการบล็อก</DialogTitle>
                <DialogContent>
                    ลูกค้ารายนี้จะไม่สามารถส่งข้อความถึงคุณได้อีก โดยคุณยังสามารถยกเลิกการบล็อกได้
                </DialogContent>
                <DialogActions>
                    <div className='px-4 pb-4'>
                        <Button onClick={() => {
                            setOpen(false);
                        }}>ยกเลิก</Button>
                        <Button
                            variant="contained"
                            onClick={() => {
                                setOpen(false);
                            }}
                            autoFocus>
                            ยืนยัน
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>
        </>
    );
}