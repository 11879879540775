//import { useState, useEffect, useRef, forwardRef } from 'react';
//import axios from 'axios';
import MainLayout from "../layout/mainLayout";

export default function Users() {
    return (
        <MainLayout
            pageTitle='พนักงาน'
            disableFrom={true}
        />
    );
}