import { useState, forwardRef, useRef, useEffect, useCallback } from 'react'
import axios from 'axios';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import DragHandleOutlinedIcon from '@mui/icons-material/DragHandleOutlined';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Switch from '@mui/material/Switch';
import Menu from '@mui/material/Menu';
import MenuList from '@mui/material/MenuList';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import useDebouncex from '../modules/useDebouncex';

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function PaperComponent(props) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}

export default function CollectMoney({ customerx, clientOrder, setClientOrder }) {

    const [open, setOpen] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertText, setAlertText] = useState({
        msg: '',
        type: 'error'
    });
    const [datap, setDatap] = useState([]);
    const dragItem = useRef(null);
    const dragOverItem = useRef(null);
    const [subSum, setSubSum] = useState(0);
    const [footerSum, setFooterSum] = useState(0);
    const [disCountType, setDisCountType] = useState('1');
    const [disCountValue, setDisCountValue] = useState(0);
    const [enableVat, setEnableVat] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [inputFields, setInputFields] = useState([{
        productID: '',
        productDescription: '',
        qty: 0,
        unitPrice: 0,
        inRowSumPrice: 0,
        isError: false,
    }]);
    const [anchorEl, setAnchorEl] = useState(null);
    const openFilter = Boolean(anchorEl);
    const handleClickFilter = (target) => {
        setAnchorEl(target);
    };

    const handleProductChange = (index, data) => {
        const list = [...inputFields];
        if (typeof data === 'object' && data !== null) {
            list[index].productID = data.id;
            list[index].productDescription = data.name;
            list[index].qty = 1;
            list[index].unitPrice = data.price;
            list[index].inRowSumPrice = parseInt(data.price) * 1;
            setInputFields(list);
        }
    }

    const addInputField = () => {
        setInputFields([...inputFields, {
            productID: '',
            productDescription: '',
            qty: 0,
            unitPrice: 0,
            inRowSumPrice: 0,
            isError: false,
        }])
    }

    const removeInputFields = (index) => {
        const rows = [...inputFields];
        rows.splice(index, 1);
        setInputFields(rows);
    }

    const handleChange = (index, evnt) => {
        const { name, value } = evnt.target;
        const list = [...inputFields];
        list[index][name] = value;

        if (value !== '') {
            list[index]['isError'] = false;
        }

        if (name === 'qty') {
            list[index].inRowSumPrice = list[index].unitPrice * value;
        }
        if (name === 'unitPrice') {
            list[index].inRowSumPrice = value * list[index].qty;
        }
        setInputFields(list);
    }

    const handleSort = () => {
        let stockItems = [...inputFields];
        const draggedItemsContent = stockItems.splice(dragItem.current, 1)[0];
        stockItems.splice(dragOverItem.current, 0, draggedItemsContent);
        dragItem.current = null;
        dragOverItem.current = null;
        setInputFields(stockItems);
    }


    const handleClickCollect = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        let sum = 0;
        for (let i = 0; i < inputFields.length; i++) {
            sum += inputFields[i].inRowSumPrice;
        }
        setSubSum(sum);
        if (disCountValue !== '' && disCountValue > 0) {
            if (disCountType === '1') {
                sum = sum - disCountValue;
            } else {
                sum = sum - ((sum * disCountValue) / 100);
            }
        }
        if (enableVat) {
            sum = sum + (sum * 7) / 100;
        }

        setFooterSum(sum);
    }, [inputFields, disCountType, disCountValue, enableVat]);


    const [searchProduct, setSearchProduct] = useState({ text: '', target: '' });

    const debouncedSearchProduct = useDebouncex(searchProduct, 500);

    const fetchProducts = useCallback((searchProduct, target) => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}/products?offset=0&search=${searchProduct}`,
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            }
        }).then(function (response) {
            if (response.data.status === 200) {
                setDatap(response.data.data);
                handleClickFilter(target)
            }
        });
    }, []);

    useEffect(() => {
        if (debouncedSearchProduct) {
            fetchProducts(debouncedSearchProduct.text, debouncedSearchProduct.target);
        }
    }, [debouncedSearchProduct, fetchProducts]);

    const sendSearch3 = (text, target) => {
        setSearchProduct({ text, target });
    };

    function fixedNUM(num, precision) {
        var roundedx = (+(Math.round(+(num + 'e' + precision)) + 'e' + -precision)).toFixed(precision);
        var commasx = roundedx.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return commasx;
    }

    const saveAndCreateOrder = () => {
        let errorCount = 0;
        for (const [key, value] of Object.entries(inputFields)) {
            if (inputFields[key].productDescription === '' || inputFields[key].qty === '' || inputFields[key].unitPrice === '') {
                errorCount++;
                let list = [...inputFields];
                list[key]['isError'] = true;
                setInputFields(list);
                setDisabled(false);
                console.log(value);
            }
        }
        if (errorCount > 0) {
            return;
        }

        axios({
            method: 'post',
            url: `${process.env.REACT_APP_BACKEND_URL}/order/add`,
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            },
            data: {
                products: inputFields,
                discount: {
                    type: disCountType,
                    value: disCountValue
                },
                vat: enableVat,
                customer: customerx
            }
        }).then(function (response) {
            setAlertOpen(true);
            if (response.data.status === 200) {
                setOpen(false);
                setAlertText({
                    msg: 'สร้างออเดอร์สำเร็จ',
                    type: 'success'
                });
                setInputFields([{
                    productID: '',
                    productDescription: '',
                    qty: '',
                    unitPrice: '',
                    inRowSumPrice: 0,
                    isError: false,
                }]);
                setDisCountType('1');
                setDisCountValue(0);
                setEnableVat(false);
                if (clientOrder) {
                    setClientOrder([response.data.order, ...clientOrder]);
                } else {
                    setClientOrder([response.data.order]);
                }

            } else {
                setAlertText({
                    msg: 'เกิดข้อผิดพลาดบางอย่างขึ้น',
                    type: 'error'
                });
            }
        });
    }

    const [isDraggable, setIsDraggable] = useState(true);

    return (
        <>
            <Tooltip title={<span style={{ fontSize: '16px' }}>สร้างรายการสั่งซื้อ</span>} placement="top" arrow>
                <button type='button' onClick={handleClickCollect}>
                    <AddOutlinedIcon />
                </button>
            </Tooltip>

            <Dialog
                maxWidth={'lg'}
                open={open}
                onClose={handleClose}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle className='flex justify-between items-center'>
                    สร้างรายการสั่งซื้อ
                    <button onClick={handleClose}>
                        <CloseOutlinedIcon sx={{ color: '#555', fontSize: 30 }} />
                    </button>
                </DialogTitle>
                <DialogContent className='flex justify-center items-center border-y'>
                    <div className=' py-4'>
                        <div className="table-warp">
                            <table>
                                <thead>
                                    <tr className="border-b border-b-slate-200 text-noto sticky top-0 z-10 bg-white">
                                        <th className=""></th>
                                        <th className="text-center w-4 pb-3 px-3 font-normal">ลำดับ</th>
                                        <th className="w-6/12 text-left pb-3 pl-5 font-normal">ชื่อสินค้า / รายละเอียด</th>
                                        <th className="w-2/12 text-center pb-3 pr-2 font-normal">จำนวน</th>
                                        <th className="w-2/12 text-right pb-3 pr-4 font-normal">ราคาต่อหน่วย</th>
                                        <th className="w-2/12 text-right pb-3 pr-4 font-normal">ราคารวม</th>
                                        <th className=""></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        inputFields.map((data, index) => {
                                            return (
                                                <tr
                                                    className="align-baseline border-b border-b-slate-200 this-can-drag"
                                                    key={index}
                                                    draggable={isDraggable}
                                                    onDragStart={(e) => (dragItem.current = index)}
                                                    onDragEnter={(e) => (dragOverItem.current = index)}
                                                    onDragEnd={handleSort}
                                                    onDragOver={(e) => e.preventDefault()}
                                                >
                                                    <td className="text-center px-2 align-middle cursor-grab">
                                                        <DragHandleOutlinedIcon />
                                                    </td>
                                                    <td className="text-center px-2">
                                                        <span style={{ verticalAlign: '-webkit-baseline-middle' }}>{index + 1}</span>
                                                    </td>
                                                    <td className="px-2 pl-4">

                                                        <TextField
                                                            error={data.isError && data.productDescription === '' ? true : false}
                                                            helperText={data.isError && data.productDescription === '' && 'กรุณาเลือกสินค้า'}
                                                            className="w-full"
                                                            variant="standard"
                                                            placeholder="พิมพ์เพื่อค้นหาสินค้า"
                                                            inputProps={{
                                                                autoComplete: 'off',
                                                                form: { autocomplete: 'off' },
                                                                style: { padding: '0.5em' }
                                                            }}
                                                            onKeyUp={(e) => { sendSearch3(e.target.value, e.currentTarget); }}
                                                            value={data.productDescription && data.productDescription}
                                                            onChange={(e) => {
                                                                handleProductChange(index, {
                                                                    id: '',
                                                                    name: e.target.value,
                                                                    price: 0
                                                                });
                                                            }}
                                                            onBlur={(e) => { e.preventDefault(); }}
                                                            name="productDescription"
                                                            onMouseDown={() => setIsDraggable(false)}
                                                            onMouseUp={() => setIsDraggable(true)}
                                                        />

                                                        <Menu
                                                            anchorEl={anchorEl}
                                                            open={openFilter}
                                                            onClose={() => setAnchorEl(null)}
                                                            disableAutoFocus={true}
                                                            disableEnforceFocus={true}
                                                        >
                                                            <Paper elevation={0} sx={{ width: '100%', maxWidth: '100%' }}>
                                                                <MenuList>
                                                                    {datap && Object.entries(datap).map(([kk, itm]) => (
                                                                        <MenuItem key={kk} onClick={() => {
                                                                            setAnchorEl(null);
                                                                            handleProductChange(index, itm);
                                                                        }}>
                                                                            <ListItemText>{itm.name}</ListItemText>
                                                                        </MenuItem>
                                                                    ))}
                                                                </MenuList>
                                                            </Paper>
                                                        </Menu>
                                                    </td>
                                                    <td className="px-2">
                                                        <TextField
                                                            error={data.isError && data.qty === '' ? true : false}
                                                            helperText={data.isError && data.qty === '' && 'กรุณากรอกจำนวน'}
                                                            className="w-full"
                                                            placeholder="จำนวน"
                                                            inputProps={{ min: 0, style: { textAlign: 'center', padding: '0.5em' } }}
                                                            variant="standard"
                                                            name="qty"
                                                            type="number"
                                                            onChange={(evnt) => { handleChange(index, evnt) }}
                                                            value={data.qty}
                                                        />
                                                    </td>
                                                    <td className="px-2 text-right">
                                                        <TextField
                                                            error={data.isError && data.unitPrice === '' ? true : false}
                                                            helperText={data.isError && data.unitPrice === '' && 'กรุณากรอกราคา'}
                                                            className="w-full"
                                                            inputProps={{ min: 0, style: { textAlign: 'right', padding: '0.5em' } }}
                                                            placeholder="ราคาต่อหน่วย"
                                                            variant="standard"
                                                            name="unitPrice"
                                                            type="number"
                                                            onChange={(evnt) => { handleChange(index, evnt) }}
                                                            value={data.unitPrice}
                                                        />
                                                    </td>
                                                    <td className="px-2 py-3">
                                                        <TextField
                                                            className="w-full"
                                                            inputProps={{ min: 0, style: { textAlign: 'right', padding: '0.5em' } }}
                                                            placeholder="ราคารวม"
                                                            variant="standard"
                                                            type="number"
                                                            value={data.inRowSumPrice}
                                                        />
                                                    </td>
                                                    <td className="px-0 text-right w-2">
                                                        {(inputFields.length !== 1) ? <button style={{ verticalAlign: '-webkit-baseline-middle' }}
                                                            onClick={() => removeInputFields(index)}><CloseOutlinedIcon /></button> : ''}
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                            <div className="mt-4 flex justify-between items-start">
                                <Button variant="text" onClick={addInputField}> + เพิ่มรายการ</Button>
                                <div>
                                    <div className='mt-3 flex items-end gap-3 justify-between'>
                                        <h5>รวม</h5>
                                        <h5>{fixedNUM(subSum, 2)} <span className='px-4' style={{ paddingRight: '1.15rem' }}>บาท</span></h5>
                                    </div>
                                    <div className='mt-3 flex items-end gap-3'>
                                        <h5>ส่วนลด</h5>
                                        <TextField
                                            className="w-28"
                                            placeholder="จำนวน"
                                            inputProps={{ min: 0, style: { textAlign: 'right', padding: '0.25em' } }}
                                            variant="standard"
                                            type="number"
                                            onChange={(e) => { setDisCountValue(e.target.value) }}
                                            value={disCountValue}
                                        />
                                        <select
                                            className='text-noto ring-0 border-0 focus:ring-0 focus:border-0'
                                            value={disCountType}
                                            onChange={(e) => {
                                                setDisCountType(e.target.value);
                                            }}
                                        >
                                            <option value={1}>บาท</option>
                                            <option value={2}>%</option>
                                        </select>
                                    </div>
                                    <div className='mt-3 flex items-center gap-3 justify-between'>
                                        <div className='-ml-3'>
                                            <Switch checked={enableVat} onChange={(e) => { setEnableVat(e.target.checked) }} />
                                        </div>
                                        <h5 className='px-4' style={{ paddingRight: '1.15rem' }}>ภาษีมูลค่าเพิ่ม 7%</h5>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </DialogContent>
                <DialogActions>
                    <div className='p-4 w-full flex justify-between items-center'>
                        <h5 className='text-xl'><small>ยอดรวมสุทธิ</small> <span className='font-bold'>{fixedNUM(footerSum, 2)}</span> <small>บาท</small></h5>
                        <div>
                            <Button onClick={handleClose}>ยกเลิก</Button>
                            <Button onClick={() => { saveAndCreateOrder(); }} variant="contained" disabled={disabled}>สร้างคำสั่งซื้อ</Button>
                        </div>
                    </div>
                </DialogActions>
            </Dialog>

            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={alertOpen} autoHideDuration={3000} onClose={() => setAlertOpen(false)}>
                <Alert onClose={() => setAlertOpen(false)} severity={alertText.type}>
                    {alertText.msg}
                </Alert>
            </Snackbar>
        </>
    );
}