import { useChatHelper } from "../modules/useChatHelper";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';


export default function ChatHelper({ setMessage, openHelper, setOpenHelper }) {
    const chatHelperList = useChatHelper();

    return (
        <>
            <Dialog
                fullWidth={true}
                open={openHelper}
                onClose={() => setOpenHelper(false)}
                maxWidth={'md'}
            >
                <DialogTitle className='flex justify-between'>
                    ข้อความที่บันทึกไว้
                    <button onClick={() => { setOpenHelper(false); }}>
                        <CloseOutlinedIcon />
                    </button>
                </DialogTitle>
                <DialogContent>
                    <div className='pt-1.5'>
                        {chatHelperList && Object.entries(chatHelperList).map(([k, item]) => (
                            <div
                                className='rounded border border-slate-150 mb-4 p-4 cursor-pointer hover:bg-slate-50'
                                key={k}
                                onClick={() => {
                                    setMessage(item.content)
                                    setOpenHelper(false)
                                }}
                            >
                                <h4 className="font-bold">{item.title}</h4>
                                <p className='truncate text-sm'>{item.content}</p>
                            </div>
                        ))}
                    </div>
                </DialogContent>

            </Dialog>
        </>

    );
}