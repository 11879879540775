import { useState } from 'react';
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';

const VideoThumbnail = ({ videoFile }) => {
    const [isPlaying, setIsPlaying] = useState(false);

    const handlePlayButtonClick = () => {
        setIsPlaying(true);
    };
    
    const thumbnail = videoFile.replace(/(\.\w+)$/, '_thumbnail.jpg').replace('/media/', '/media/thumbnails/');

    return (
        <div className="video-thumbnail rounded-xl my-1 max-w-xs aspect-video relative z-10 mb-2 w-96">
            {!isPlaying ? (
                <div className="thumbnail-container flex justify-center items-center w-full h-full rounded-xl bg-slate-200" style={{
                    backgroundImage: `url(${thumbnail})`,
                    backgroundPosition: `center center`,
                    backgroundSize: `cover`,
                    backgroundRepeat: `no-repeat`
                }}>
                    <button className="play-button bg-white/90 w-14 h-14 rounded-full text-indigo-800" onClick={handlePlayButtonClick}>
                        <PlayArrowOutlinedIcon sx={{ fontSize: '2rem'}} />
                    </button>
                </div>
            ) : (
                <video src={videoFile} width={1920} height={1080} controls autoPlay className='rounded-xl aspect-video bg-gray-500' />
            )}
        </div>
    );
};

export default VideoThumbnail;
