import { useState } from 'react';
import { useNavigate } from "react-router-dom";

import MainLayout from "../layout/mainLayout";
import Button from '@mui/material/Button';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export default function ProductEditor() {
    const navigate = useNavigate();

    const [value, setValue] = useState('');


    return (
        <>
            <MainLayout
                //pageTitle='สินค้า / บริการ'
                //disableFrom={true}
                removePadding={true}
                pageContent={
                    <div id="real-main" className='flex flex-col justify-between px-6' >
                        <div className='flex justify-between items-center mt-4'>
                            <h1 className='font-bold text-xl '>
                                <button onClick={() => { navigate('/products') }}>
                                    <ArrowBackOutlinedIcon />
                                </button>
                                สินค้า / บริการ
                            </h1>
                            <div className='flex gap-4'>

                                <Button variant="contained" onClick={() => {

                                }}> <SaveOutlinedIcon />บันทึกข้อมูล</Button>

                            </div>
                        </div>
                        <div className='table-responsive mt-4 flex gap-4'>
                            <div className='w-9/12'>
                                <div className='mb-4'>
                                    <input type="text" className="bg-white border border-gray-300 text-gray-900 text-lg font-bold focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 text-noto" placeholder="ชื่อสินค้า" required />
                                </div>


                                <div className='bg-white'>
                                    <ReactQuill placeholder="รายเอียดย่อ" theme="snow" value={value} onChange={setValue} />
                                </div>



                            </div>
                            <div className='w-3/12'>

                                <div className="flex items-center justify-center w-full mb-4">
                                    <label htmlFor="dropzone-file" className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed  cursor-pointer bg-white hover:bg-gray-100 ">
                                        <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                            <svg aria-hidden="true" className="w-10 h-10 mb-3 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                                            <p className="mb-2 text-sm text-gray-500 text-noto"><span className="font-semibold">คลิกเพื่ออัปโหลด</span> หรือลากวาง</p>
                                            <p className="text-xs text-gray-500 text-noto">รองรับไฟล์ JPG, JPEG, PNG และ WEBP</p>
                                        </div>
                                        <input id="dropzone-file" type="file" className="hidden" />
                                    </label>
                                </div>

                                <div className='mb-4'>
                                    <input type="number" className="bg-white border border-gray-300 text-gray-900 text-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " placeholder="ราคา" required />
                                </div>

                            </div>
                        </div>


                    </div>
                }
                //submitSearch={submitSearch}
                //searchTextChange={search}
                disableFrom={true}
            />

        </>

    );
}