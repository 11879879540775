import Skeleton from '@mui/material/Skeleton';
export default function ChatSkt() {
    return (
        <>

            <div className="message__chats" >
                <div className='message__recipient flex'>
                    <div className="w-14 pt-1">
                        <Skeleton variant="circular" width={40} height={40} />
                    </div>
                    <div>
                        <div className='relative'>
                            <Skeleton variant="rounded" width={210} height={50} />
                            <div className="absolute left-0 top-1/2 transform -translate-x-1/2 rotate-45 w-2 h-2 bg-slate-200"></div>
                        </div>
                        <div className='relative mt-2'>
                            <Skeleton variant="rounded" width={210} height={120} />
                            <div className="absolute left-0 top-1/2 transform -translate-x-1/2 rotate-45 w-2 h-2 bg-slate-200"></div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="message__chats flex justify-end w-full">
                <Skeleton variant="rounded" width={210} height={50} />
            </div>

            <div className="message__chats" >
                <div className='message__recipient flex'>
                    <div className="w-14 pt-1">
                        <Skeleton variant="circular" width={40} height={40} />
                    </div>
                    <div>
                        <div className='relative'>
                            <Skeleton variant="rounded" width={310} height={50} />
                            <div className="absolute left-0 top-1/2 transform -translate-x-1/2 rotate-45 w-2 h-2 bg-slate-200"></div>
                        </div>
                        <div className='relative mt-2'>
                            <Skeleton variant="rounded" width={180} height={50} />
                            <div className="absolute left-0 top-1/2 transform -translate-x-1/2 rotate-45 w-2 h-2 bg-slate-200"></div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="message__chats flex justify-end w-full">
                <Skeleton variant="rounded" width={310} height={50} />
            </div>
            <div className="message__chats flex justify-end w-full mt-2">
                <Skeleton variant="rounded" width={210} height={50} />
            </div>

            <div className="message__chats" >
                <div className='message__recipient flex'>
                    <div className="w-14 pt-1">
                        <Skeleton variant="circular" width={40} height={40} />
                    </div>
                    <div>
                        <div className='relative'>
                            <Skeleton variant="rounded" width={110} height={50} />
                            <div className="absolute left-0 top-1/2 transform -translate-x-1/2 rotate-45 w-2 h-2 bg-slate-200"></div>
                        </div>
                        <div className='relative mt-2'>
                            <Skeleton variant="rounded" width={380} height={50} />
                            <div className="absolute left-0 top-1/2 transform -translate-x-1/2 rotate-45 w-2 h-2 bg-slate-200"></div>
                        </div>
                        <div className='relative mt-2'>
                            <Skeleton variant="rounded" width={210} height={180} />
                            <div className="absolute left-0 top-1/2 transform -translate-x-1/2 rotate-45 w-2 h-2 bg-slate-200"></div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}