import { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import MainLayout from "../layout/mainLayout";
import Pagination from '@mui/material/Pagination';
import PageSKT from '../components/tableSkt';
import axios from 'axios';
import Tooltip from '@mui/material/Tooltip';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Avatar from '@mui/material/Avatar';
import ArrowRightAltOutlinedIcon from '@mui/icons-material/ArrowRightAltOutlined';

export default function Orders() {

    const [data, setData] = useState([]);
    const [allPage, setAllPage] = useState(0);
    const page = useRef(0);
    const tableRef = useRef(null);
    useEffect(() => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}/orders?offset=${page.current}`,
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            }
        }).then(function (response) {
            if (response.data.status === 200) {
                setData(response.data.data);
                setAllPage(response.data.all);
            }
        });
        document.title = `คำสั่งซื้อ - ${process.env.REACT_APP_SITE_NAME}`;
    }, []);

    const handlePageChange = (event, value) => {
        page.current = value - 1;
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}/orders?offset=${page.current}`,
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            }
        }).then(function (response) {
            if (response.data.status === 200) {
                tableRef.current?.scrollIntoView({ behavior: 'smooth' });
                setData(response.data.data);
                setAllPage(response.data.all);
            }
        });
    }

    return (
        <MainLayout
            //pageTitle='สินค้า / บริการ'
            disableFrom={true}
            removePadding={true}
            pageContent={
                <div id="real-main" className='flex flex-col justify-between px-6' ref={tableRef}>
                    <div className='flex justify-between items-center mt-4'>
                        <div className='flex justify-between items-center gap-4'>
                            <h1 className='font-bold text-xl '>คำสั่งซื้อจากแชท</h1>

                            <Link to='/order-from-web' className='bg-slate-100 rounded-full px-4 py-2 text-sm flex justify-center gap-2 items-center'>
                                <span>คำสั่งซื้อจากเว็บไซต์</span> <ArrowRightAltOutlinedIcon sx={{ fontSize: '16px' }} />
                            </Link>
                        </div>
                        <div className='flex gap-4'>

                        </div>
                    </div>
                    <div className='table-responsive mt-2 bg-white'>
                        <table className="table-auto w-full border-collapse border border-slate-150">
                            <thead>
                                <tr>
                                    <th className='text-left border-b border-slate-150 p-4 pl-8 pt-3 pb-3 font-bold text-noto'>เลขที่</th>
                                    <th className='text-left border-b border-slate-150 p-4 pl-8 pt-3 pb-3 font-bold text-noto'>ลูกค้า</th>
                                    <th className='text-left border-b border-slate-150 p-4 pl-8 pt-3 pb-3 font-bold text-noto'>วันที่</th>
                                    <th className='text-right border-b border-slate-150 p-4 pl-8 pt-3 pb-3 font-bold text-noto'>รวม</th>
                                    <th className='text-right border-b border-slate-150 p-4 pl-8 pr-6 pt-3 pb-3 font-bold text-noto'></th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.length > 0 ? Object.entries(data).map(([k, item]) => (
                                    <tr className='align-center border-b border-b-slate-150' key={k}>
                                        <td className='p-4 py-1 pl-8'>
                                            #{item.id}
                                        </td>
                                        <td className='p-4 pl-8 flex gap-4 items-center'>
                                            <Avatar alt={item.name}
                                                src={item.avatar && item.avatar.includes('https://') ? item.avatar :
                                                    `${process.env.REACT_APP_BACKEND_URL}/image/${item.avatar}/45`}
                                            />
                                            <h4 className=''>{item.customer ? item.customer : item.email}</h4>

                                        </td>
                                        <td className='p-4 pl-8'>
                                            <p>{item.date}</p>
                                        </td>
                                        <td className='p-4 pl-8 text-right'>
                                            {item.total} <small>฿</small>
                                        </td>
                                        <td className='p-4 pl-8 text-right'>
                                            <div className='flex gap-2 justify-end'>
                                                <Tooltip title={<span style={{ fontSize: '16px' }}>แก้ไขสินค้า</span>} placement="top" arrow>
                                                    <button
                                                        className='rounded-full flex justify-center items-center w-8 h-8 bg-slate-100'
                                                    >
                                                        <EditOutlinedIcon sx={{ fontSize: '16px' }} />
                                                    </button>
                                                </Tooltip>

                                            </div>
                                        </td>
                                    </tr>
                                )) : <PageSKT tr={15} td={5} />}
                            </tbody>
                        </table>

                    </div>

                    <div className='flex p-4 justify-between items-center sticky bottom-0 bg-slate-50'>
                        <div className='text-noto'>
                            หน้าที่ {page.current + 1} / {allPage}
                        </div>
                        <Pagination count={allPage} page={page.current + 1} onChange={handlePageChange} />
                    </div>
                </div>
            }
        //submitSearch={submitSearch}
        //searchTextChange={search}
        />
    );
}