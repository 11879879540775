import { useState, forwardRef } from 'react'
import ShortcutOutlinedIcon from '@mui/icons-material/ShortcutOutlined';
import axios from 'axios';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import EmojiEmotionsOutlinedIcon from '@mui/icons-material/EmojiEmotionsOutlined';
import SpeakerNotesOutlinedIcon from '@mui/icons-material/SpeakerNotesOutlined';
import ChatHelper from '../components/chatHelper'
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Tooltip from '@mui/material/Tooltip';
import CreateQrCodePromptPay from '../components/CreateQrCodePromptPay';
import { TextField } from '@mui/material';

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ChatFooter = ({ socket, activeChat, socialType, setUploadMediaSkt, setSendMsgSkt, sendRead }) => {

  const [popImage, setPopImage] = useState(null);
  const [open, setOpen] = useState(false);
  const [showSticker, setShowSticker] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const types = ['image/png', 'image/jpeg', 'image/jpg'];
  const images = importAll(require.context('../assets/sticker', false, /\.(png|jpe?g|svg)$/));
  const imageKeys = Object.keys(images);
  const stickkerClass = 'aspect-square object-contain w-24 hover:bg-slate-100 rounded-2xl cursor-pointer';
  const [message, setMessage] = useState("");
  const [errorMsg, setErrorMsg] = useState('');

  const handleClose = () => {
    setOpen(false);
  };

  const handleTyping = (e) => {
    if (e.target.value.length > 0) {
      socket.emit("typing", {
        typeMsg: `${localStorage.getItem("name")} กำลังพิมพ์...`,
        typeUser: localStorage.getItem("user"),
        typeToClient: activeChat.chatID
      })
    } else {
      socket.emit("typing", ``)
    }
  }

  /*
  function getTextWidth(text) {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    context.font = '16px Arial';
    const width = context.measureText(text).width;
    return width;
  }
  */

  const handleSendMessage = async (e) => {

    e.preventDefault()

    if (message === '' || message.length < 1) { return }


    /*
    await setSendMsgSkt(prev => ({
      ...prev,
      'display': true,
      'width': getTextWidth(message),
      'msg': message,
    }));

    document.getElementById('chat-warp').scrollTop = document.getElementById('chat-warp').scrollHeight
    */

    if (message.trim() && localStorage.getItem("name")) {

      let sendUrl = '/chat/send';
      if (socialType === 2) {
        sendUrl = '/chat/send-facebook';
      }

      axios({
        method: 'post',
        url: `${process.env.REACT_APP_BACKEND_URL}${sendUrl}`,
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/json'
        },
        data: {
          admin: localStorage.getItem("user"),
          client: activeChat.chatID,
          msg_type: 'text',
          msg: message,
        }
      }).then(function (response) {

     
        
        setSendMsgSkt(prev => ({
          ...prev,
          'display': false,
          'width': 210
        }));
        let scroll_to_bottom = document.getElementById('chat-warp');
        scroll_to_bottom.scrollTop = scroll_to_bottom.scrollHeight;
        
        if (response.data.status === 200) {
          /*
          socket.emit("message", {
            msg: message,
            admin: localStorage.getItem("user"),
            user: activeChat.chatID,
            client: activeChat.chatID,
            previewMsg: message,
            source: socialType,
            type: 'text',
            name: localStorage.getItem("name"),
            id: `${socket.id}${Math.random()}`,
            socketID: socket.id
          })
          */
        } else {
          setAlertOpen(true);
          setErrorMsg(response.data.msg);
        }

      });
    }

    socket.emit("message", {
      msg: message,
      admin: localStorage.getItem("user"),
      user: activeChat.chatID,
      client: activeChat.chatID,
      previewMsg: message,
      source: socialType,
      type: 'text',
      name: localStorage.getItem("name"),
      id: `${socket.id}${Math.random()}`,
      socketID: socket.id,
      time: Date.now()
    })

    //console.log(`Send time: ${Date.now()}`);


    socket.emit("typing", ``)
    setMessage("")
  }

  function handleKeyDown(event) {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSendMessage(event);
    }
  }

  function handleDrop(event) {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file && types.includes(file.type)) {
      const reader = new FileReader();
      reader.onload = function (event) {
        const imageUrl = event.target.result;
        setPopImage(imageUrl);
        setOpen(true);
      };
      reader.readAsDataURL(file);
    } else {
      setAlertOpen(true)
      setErrorMsg('Please select an image file (png, jpg, jpeg)');
    }
  }

  const handlePaste = (e) => {
    if (e.clipboardData.files.length) {
      const fileObject = e.clipboardData.files[0];
      if (fileObject && types.includes(fileObject.type)) {
        const reader = new FileReader();
        reader.onload = function (event) {
          const imageUrl = event.target.result;
          setPopImage(imageUrl);
          setOpen(true);
        };
        reader.readAsDataURL(fileObject);
      } else {
        setAlertOpen(true)
        setErrorMsg('Please select an image file (png, jpg, jpeg)');
      }
    }
  }

  const uploadFile = async (base64, xtype) => {

    handleClose();
    await setUploadMediaSkt(true);
    document.getElementById('chat-warp').scrollTop = document.getElementById('chat-warp').scrollHeight

    let sendUrl = '/chat/send';
    if (socialType === 2) {
      sendUrl = '/chat/send-facebook';
    }

    axios({
      method: 'post',
      url: `${process.env.REACT_APP_BACKEND_URL}${sendUrl}`,
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
        'Content-Type': 'application/json'
      },
      data: {
        admin: localStorage.getItem("user"),
        client: activeChat.chatID,
        msg_type: 'image',
        msg: xtype === 'qr' ? base64 : popImage,
      }
    }).then(function (response) {
      setUploadMediaSkt(false);
      socket.emit("message", {
        msg: response.data.msg,
        admin: localStorage.getItem("user"),
        user: activeChat.chatID,
        client: activeChat.chatID,
        previewMsg: 'คุณส่งรูปภาพ',
        source: socialType,
        type: 'image',
        name: localStorage.getItem("name"),
        id: `${socket.id}${Math.random()}`,
        socketID: socket.id,
        time: Date.now()
      })

    });
  }

  function importAll(r) {
    let images = {};
    r.keys().forEach((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
  }

  const sendSticker = (imageName) => {
    const nameWithoutExtension = imageName.slice(0, -4);
    setUploadMediaSkt(true);
    setShowSticker(false);
    let sendUrl = '/chat/send';
    if (socialType === 2) {
      sendUrl = '/chat/send-facebook';
    }
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_BACKEND_URL}${sendUrl}`,
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
        'Content-Type': 'application/json'
      },
      data: {
        admin: localStorage.getItem("user"),
        client: activeChat.chatID,
        msg_type: 'sticker',
        msg: nameWithoutExtension,
      }
    }).then(function (response) {
      setUploadMediaSkt(false);
      if (response.data.status === 200) {
        socket.emit("message", {
          msg: nameWithoutExtension,
          admin: localStorage.getItem("user"),
          user: activeChat.chatID,
          client: activeChat.chatID,
          previewMsg: 'คุณส่งสติ๊กเกอร์',
          source: socialType,
          type: 'sticker',
          name: localStorage.getItem("name"),
          id: `${socket.id}${Math.random()}`,
          socketID: socket.id,
          time: Date.now()
        })

      } else {
        console.error(response.data.msg);
      }
    });
  };

  const [openHelper, setOpenHelper] = useState(false);

  const handleClickUploadImage = () => {
    const fileInput = document.createElement("input");
    fileInput.setAttribute("type", "file");
    fileInput.style.display = "none";
    fileInput.addEventListener("change", handleUploadbyClickUpload);
    fileInput.click();
  }

  const handleUploadbyClickUpload = (event) => {
    let selectedFile = event.target.files[0];

    if (selectedFile && types.includes(selectedFile.type)) {
      let reader = new FileReader();
      reader.onload = (event) => {
        setPopImage(event.target.result);
        setOpen(true);
      }
      reader.readAsDataURL(selectedFile);
    } else {
      setAlertOpen(true)
      setErrorMsg('Please select an image file (png, jpg, jpeg)');
    }
  }

  return (
    <>
      {activeChat.chatID &&
        <div className='p-5'>
          {showSticker && activeChat.source === 1 &&
            <div className='flex justify-between gap-2 py-4'>

              {imageKeys.map((key) => (
                <img
                  key={key}
                  src={images[key]}
                  className={stickkerClass}
                  alt={key}
                  onClick={() => sendSticker(key)}
                />
              ))}

            </div>
          }

          <form className='flex w-full items-end flex-wrap' onSubmit={handleSendMessage}>
            <div className='flex items-start w-full border border-gray-300 rounded-lg'>
              <TextField
                placeholder='เขียนข้อความ'
                className='w-full block p-4 text-gray-900 border-0 bg-gray-50 focus:outline-none resize-y'
                value={message}
                onChange={e => setMessage(e.target.value)}
                onInput={handleTyping}
                onKeyDown={handleKeyDown}
                onPaste={(e) => handlePaste(e)}
                onDrop={handleDrop}
                minRows={4}
                multiline
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                  sx: {
                    left: '1rem',
                    top: '0.25rem',
                    fontFamily: 'sarabun',
                  }
                }}
              />

              <Tooltip title={<span style={{ fontSize: '16px' }}>ส่งข้อความ</span>} placement="top" arrow>
                <button className="p-2">
                  <ShortcutOutlinedIcon />
                </button>
              </Tooltip>

              {activeChat.source === 1 &&
                <Tooltip title={<span style={{ fontSize: '16px' }}>ส่งสติ๊กเกอร์</span>} placement="top" arrow>
                  <button type='button' className="p-2" onClick={() => setShowSticker(!showSticker)}>
                    <EmojiEmotionsOutlinedIcon />
                  </button>
                </Tooltip>
              }

              <Tooltip title={<span style={{ fontSize: '16px' }}>แนบรูปภาพ</span>} placement="top" arrow>
                <button type='button' className="p-2" onClick={handleClickUploadImage}>
                  <AttachFileOutlinedIcon />
                </button>
              </Tooltip>

              <Tooltip title={<span style={{ fontSize: '16px' }}>ข้อความที่บันทึกไว้</span>} placement="top" arrow>
                <button type='button' className="p-2" onClick={() => setOpenHelper(true)}>
                  <SpeakerNotesOutlinedIcon />
                </button>
              </Tooltip>

              <CreateQrCodePromptPay
                popImage={popImage}
                setPopImage={setPopImage}
                uploadFile={uploadFile}
              />

            </div>
          </form>

        </div>

      }

      <Dialog
        maxWidth={'sm'}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            position: "inherit"
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          {"ต้องการส่งไฟล์ในแชทนี้หรือไม่ ?"}
        </DialogTitle>
        <DialogContent className='flex justify-center items-center bg-transparent'>
          <button onClick={handleClose} className='absolute top-1 right-1'>
            <CloseOutlinedIcon sx={{ color: '#fff', fontSize: 40 }} />
          </button>
          <img style={{ maxHeight: '85vh' }}
            src={popImage}
            alt={`upload`}
          />
        </DialogContent>
        <DialogActions>
          <div className='px-4 pb-4'>
            <Button onClick={handleClose}>ยกเลิก</Button>
            <Button onClick={uploadFile} variant="contained">ส่งไฟล์</Button>
          </div>
        </DialogActions>
      </Dialog>

      <ChatHelper
        setMessage={setMessage}
        openHelper={openHelper}
        setOpenHelper={setOpenHelper}
      />

      <Snackbar open={alertOpen} autoHideDuration={3000} onClose={() => setAlertOpen(false)}>
        <Alert onClose={() => setAlertOpen(false)} severity="error">
          {errorMsg}
        </Alert>
      </Snackbar>
    </>
  )
}

export default ChatFooter