import { useEffect } from 'react';
import toast, { Toaster } from "react-hot-toast";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";

export default function ToastNotification({ socket, eventName }) {

    useEffect(() => {
        if (socket) {
            socket.on(eventName, (data) => {
                notify(data);
            });
        }
    }, [socket, eventName]);

    const notify = (socketData) =>
        toast.custom((t) => (
            <div
                className={`${t.visible ? "animate-enter" : "animate-leave"
                    } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
            >
                <div className="flex-1 w-0 p-4">
                    <div className="flex items-start">
                        <div className="flex-shrink-0 pt-0.5">
                            <NotificationsActiveOutlinedIcon />
                        </div>
                        <div className="ml-3 flex-1">
                            <p className="text-sm font-medium text-gray-900 text-noto">{socketData ? socketData.title : ''}</p>
                            <p className="mt-1 text-sm text-gray-500">
                                {socketData ? socketData.msg : ''}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="flex border-l border-gray-200">
                    <button
                        onClick={() =>  toast.dismiss(t.id) }
                        className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    >
                        ปิด
                    </button>
                </div>
            </div>
        ));

    return (
        <Toaster
            position="bottom-left"
            reverseOrder={false}
            toastOptions={{
                duration: 60000,
            }}
        />
    );
}