import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react'
import axios from 'axios';
import Avatar from '@mui/material/Avatar';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import ChatSkt from './chatSkt';
import Skeleton from '@mui/material/Skeleton';
//import ImageWithFallback from './ImageWithFallback';
import VideoThumbnail from './VideoThumbnail'
/*
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
*/

import _ from 'lodash';
import { useClickAway } from 'react-use';

const ChatBody = ({ messages, lastMessageRef, chatid, uploadMediaSkt, sendMsgSkt, activeChat, setActiveChat, setMessages, searchMsgID, readState, showSingleSearch, setShowSingleSearch, keyword, setKeyword, foundMessages, setFoundMessages, isDropdownVisible, setIsDropdownVisible, showNotfound, setShowNotFound, HandleClickCustomer }) => {

  const [oldMsg, setOldMsg] = useState({
    offset: 0,
    all: 1,
    data: null
  });

  const msgPage = useRef(null);
  const client = useRef(null);
  const allPage = useRef(null);
  const [loading, setLoading] = useState(false);

  const [lastFetchedOffset, setLastFetchedOffset] = useState(0);
  const before = useRef(0);
  const after = useRef(0);
  const [scrollToPositions, setScrollToPositions] = useState(0);

  const myDivRef = useRef(null);
  const innerChatScreen = useRef(null);

  useEffect(() => {
    const myDiv = myDivRef.current;
    after.current = innerChatScreen.current.offsetHeight;
    setScrollToPositions(innerChatScreen.current.offsetHeight);
    const handleScroll = () => {
      if (client.current == null) { return; }
      if (allPage.current === msgPage.current) { return; }
      if (myDiv.scrollTop < 1) {
        before.current = innerChatScreen.current.offsetHeight;
        fetchSingleCaht();
      }
    };
    myDiv.addEventListener('scroll', handleScroll);
    return () => {
      myDiv.removeEventListener('scroll', handleScroll);
    };
  }, [lastFetchedOffset]);

  /*
  const scrollToMessage = (msgID) => {

    const chatElement = document.querySelector(`[data-chat-id="${msgID}"]`);
    if (chatElement && innerChatScreen.current) {
      chatElement.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
    } else {
      innerChatScreen.current.scrollTop = 0;
    }
    
  };
  */

  const fetchSingleCaht = () => {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_BACKEND_URL}/chat?offset=${msgPage.current}&client=${client.current}`,
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
        'Content-Type': 'application/json'
      },
    }).then(function (response) {
      setOldMsg(prev => ({
        ...prev,
        offset: response.data.offset,
        all: response.data.allpage,
        data: [...response.data.data, ...prev.data]
      }));

      setLastFetchedOffset(msgPage.current);
      msgPage.current = response.data.next;
      allPage.current = response.data.allpage;
    });
  }


  useEffect(() => {
    let position = after.current - before.current;
    myDivRef.current.scrollTo(0, position)
  }, [scrollToPositions]);


  useEffect(() => {
    setLoading(true);
    setMessages([]);
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_BACKEND_URL}/chat?offset=0&client=${chatid}`,
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
        'Content-Type': 'application/json'
      },
    }).then(function (response) {
      if (response.data.status === 200) {
        setOldMsg(prev => ({
          ...prev,
          offset: response.data.offset,
          all: response.data.allpage,
          data: response.data.data
        }));
        setActiveChat(prev => ({
          ...prev,
          chatID: response.data.data[0].user,
          avatar: response.data.data[0].avatar,
          name: response.data.data[0].name,
          source: response.data.data[0].source,
          status: response.data.data[0].status
        }));

        client.current = response.data.data[0].user;
        msgPage.current = response.data.next;
        allPage.current = response.data.allpage;
        setLoading(false);
        if (searchMsgID === null) {
          let scroll_to_bottom = myDivRef.current;
          scroll_to_bottom.scrollTop = scroll_to_bottom.scrollHeight;
        }
      }
    });

  }, [chatid, setActiveChat, setMessages, searchMsgID])

  //const handleImageLoad = () => {
  /*
  let scroll_to_bottom = myDivRef.current;
  if (searchMsgID !== null || showSingleSearch === true || scroll_to_bottom.scrollTop >= 0) {
    return;
  }
  if (msgPage.current === 1) {

    scroll_to_bottom.scrollTop = scroll_to_bottom.scrollHeight;
  }
  */
  //};

  useEffect(() => {
    if (msgPage.current === 1 && searchMsgID === null && !showSingleSearch) {
      let scroll_to_bottom = myDivRef.current;
      scroll_to_bottom.scrollTop = scroll_to_bottom.scrollHeight;
    }
    if (searchMsgID !== null) {
      //scrollToMessage(searchMsgID);
    }
  }, [oldMsg, searchMsgID, showSingleSearch])


  function convertDateToThai(dateString) {
    const date = new Date(dateString);
    const now = new Date();
    const yesterday = new Date(now);
    yesterday.setDate(now.getDate() - 1);
    if (date.toISOString().slice(0, 10) === now.toISOString().slice(0, 10)) {
      return date.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' });
    } else if (date.toISOString().slice(0, 10) === yesterday.toISOString().slice(0, 10)) {
      return "เมื่อวาน";
    } else if ((now.getTime() - date.getTime()) / (1000 * 3600 * 24) <= 7) {
      const dayOfWeek = date.getDay();
      const daysOfWeek = ['จันทร์', 'อังคาร', 'พุธ', 'พฤหัส', 'ศุกร์', 'เสาร์', 'อาทิตย์'];
      return daysOfWeek[dayOfWeek - 1];
    } else {
      const day = date.getDate();
      const month = ['ม.ค.', 'ก.พ.', 'มี.ค.', 'เม.ย.', 'พ.ค.', 'มิ.ย.', 'ก.ค.', 'ส.ค.', 'ก.ย.', 'ต.ค.', 'พ.ย.', 'ธ.ค.'][date.getMonth()];
      const year = date.getFullYear();
      return `${day} ${month} ${year}`;
    }
  }

  const [popImage, setPopImage] = useState(null);
  const [open, setOpen] = useState(false);
  const handleClose = () => { setOpen(false); };

  function importAll(r) {
    let images = {};
    r.keys().forEach((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
  }

  const images = importAll(require.context('../assets/sticker', false, /\.(png|jpe?g|svg)$/));


  const autoAddAhrefTag = (text) => {
    const regex = /(https?:\/\/[^\s]+)/g;
    text = text.replace(regex, `<a class='underline' href="$1" target="_blank" rel="nofollow">$1</a>`);
    if (keyword.trim() !== '') {
      const escapedKeyword = keyword.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');
      const keywordRegex = new RegExp(escapedKeyword, 'gi');
      text = text.replace(keywordRegex, `<strong>${keyword}</strong>`);
    }
    return text;
  }

  const shouldDisplayReadLabel = (send, read) => {
    const sendTime = parseInt(send, 10);
    const readTime = parseInt(read, 10);
    const timeDifference = readTime - sendTime;
    return timeDifference >= 0;
  };


  const dropdownRef = useRef(null);
  const refs = useRef([]);

  const addToRefs = (el) => {
    if (el && !refs.current.includes(el)) {
      refs.current.push(el);
    }
  };


  useClickAway(dropdownRef, () => {
    if (isDropdownVisible) {
      setIsDropdownVisible(false);
    }
  });

  const searchChatMessage = useCallback((value) => {
    if (!value) {
      setFoundMessages([]);
      setIsDropdownVisible(false);
      setShowNotFound(false);
      return;
    }

    axios({
      method: 'get',
      url: `${process.env.REACT_APP_BACKEND_URL}/chat/${chatid}/search?keyword=${value}`,
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
        'Content-Type': 'application/json',
      },
    }).then(function (response) {
      if (response.data.status === 200) {
        let productData = response.data.data;
        setFoundMessages(productData);
        setIsDropdownVisible(true);
        if (productData.length === 0) {
          setShowNotFound(true);
        }
      }
    });
  }, [chatid, setFoundMessages, setIsDropdownVisible, setShowNotFound]);

  const debounceSearch = useMemo(() => _.debounce(searchChatMessage, 300), [searchChatMessage]);

  useEffect(() => {
    return () => {
      debounceSearch.cancel();
    }
  }, [debounceSearch]);

  const onInputChange = (event) => {
    setKeyword(event.target.value);
    debounceSearch(event.target.value);
  };

  function HighlightedText({ text, highlight }) {
    const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
    return (
      <span>
        {parts.map((part, i) =>
          part.toLowerCase() === highlight.toLowerCase() ?
            <span key={i} className="font-bold text-gray-800">{part}</span> : part
        )}
      </span>
    );
  }


  const getChatMessageBetween = (id) => {

    axios({
      method: 'get',
      url: `${process.env.REACT_APP_BACKEND_URL}/chat/get-between?user=${chatid}&target=${id}`,
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
        'Content-Type': 'application/json',
      },
    }).then(async function (response) {
      if (response.data.status === 200) {

        await setMessages([]);
        setOldMsg({
          offset: 0,
          all: 1,
          data: response.data.data
        });


        setTimeout(() => {

          const msgElement = document.querySelector(`[data-chat-id='${id}']`);
          if (msgElement) {
            msgElement.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'start' });
          }

        }, 150);
    
      }
    });
  }


  //const [currentChatId, setCurrentChatId] = useState(null);

  /*
  useEffect(() => {
    if (oldMsg && oldMsg.data && oldMsg.data.length > 0 && currentChatId) {
      const divToScrollTo = refs.current.find(
        (div) => div.getAttribute("data-chat-id") === currentChatId.toString()
      );

      if (divToScrollTo) {
        divToScrollTo.scrollIntoView({ behavior: "smooth", block: 'center', inline: 'center' });
      }
    }
  }, [oldMsg, currentChatId]);
  */


  const [imageDimensions, setImageDimensions] = useState({});


  const handleImageLoad = async (src) => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      let aspectRatio = img.naturalWidth / img.naturalHeight;
      let newHeight = Math.floor(240 / aspectRatio);
      setImageDimensions(prev => ({
        ...prev,
        [src]: { width: 240, height: newHeight }
      }));
    };

    let scroll_to_bottom = myDivRef.current;
    if (searchMsgID !== null || showSingleSearch === true) {
      return;
    }
    if (msgPage.current === 1) {
      scroll_to_bottom.scrollTop = scroll_to_bottom.scrollHeight;
    }
  };



  const imageClass = 'inline-block w-28 lg:w-60 max-w-full cursor-pointer rounded-xl relative z-10 mb-2 max-h-96 object-cover';
  return (
    <>

      {showSingleSearch &&
        <div className='w-full' ref={dropdownRef}>
          <div className='w-full flex gap-4 items-center bg-white border-b border-t px-6'>
            <input
              type='search'
              className='focus:outline-0 w-full p-2 text-noto bg-transparent' placeholder='ค้นหาข้อความ...'
              onChange={onInputChange}
            />

            <button onClick={() => setShowSingleSearch(false)}>
              <CloseOutlinedIcon />
            </button>
          </div>
          <div className="relative z-50">
            {isDropdownVisible && foundMessages.length > 0 && (
              <div className="dropdown-list shadow overflow-auto absolute w-full bg-white z-10 -top-2 p-4" style={{ maxHeight: '100px' }}>
                {foundMessages.map(product => (
                  <div
                    key={product.id}
                    className="dropdown-list-item py-2 px-4 cursor-pointer hover:bg-gray-100 truncate text-gray-500 text-noto"
                    onClick={() => {
                      getChatMessageBetween(product.id);
                      //setCurrentChatId(product.id);
                    }}
                  >
                    <HighlightedText text={product.msg} highlight={keyword} />
                  </div>
                ))}
              </div>
            )}
            {showNotfound && foundMessages.length <= 0 &&
              <div className="dropdown-list shadow overflow-auto absolute w-full bg-white z-10 -top-2 p-4" style={{ maxHeight: '100px' }}>
                <div className='text-center'>
                  ไม่พบข้อความตรงตามคำค้นหา
                </div>
              </div>
            }
          </div>
        </div>
      }


      <div className={`relative overflow-x-hidden px-7 pt-5 mb-auto max-h-fit`} ref={myDivRef} id="chat-warp">
        <div className='table-responsive pt-7 min-h-80'>
          <div className='flex flex-wrap' >
            <div className='message__container w-full' id="inner-chat" ref={innerChatScreen} >
              {loading ? <ChatSkt /> :
                oldMsg.data !== null && Object.entries(oldMsg.data).map(([k, item]) => (
                  item.from === 2 ? (
                    <div className={`message__chats chat__type__${item.type}`} key={item.time + k} data-chat-id={item.id} ref={addToRefs}>
                      <p className='sender__name text-right block mt-2 mb-0.5'>{item.uname === localStorage.getItem('name') ? '' : item.uname}</p>
                      <div className='message__sender text-right relative'>
                        {item.type === 'text' &&
                          <>
                            {item.c_read === 1 && <span className='text-xs pr-1 text-gray-400'>Read</span>}
                            {item.c_read === 0 && shouldDisplayReadLabel(item.unix, readState[activeChat.chatID]?.time) ? <span className='text-xs pr-1 text-gray-400'>Read</span> : ''}
                            <span
                              className='bg-indigo-800 text-white py-2 px-3 rounded-xl inline-block w-52 lg:w-auto max-w-xs lg:max-w-md text-left leading-tight whitespace-pre-wrap relative chat-text-box'
                              dangerouslySetInnerHTML={{ __html: autoAddAhrefTag(item.msg) }}
                            >
                            </span>
                          </>
                        }

                        {item.type === 'image' &&
                          <> {item.c_read === 1 && <span className='text-xs pr-1 text-gray-400'>Read</span>}

                            <img
                              alt={item.msg}
                              src={`${process.env.REACT_APP_BACKEND_URL}/media/${item.msg}.png`}
                              width={imageDimensions[`${process.env.REACT_APP_BACKEND_URL}/media/${item.msg}.png`]?.width}
                              height={imageDimensions[`${process.env.REACT_APP_BACKEND_URL}/media/${item.msg}.png`]?.height}
                              effect={"blur"}
                              className={imageClass}
                              onClick={() => { setPopImage(`${process.env.REACT_APP_BACKEND_URL}/media/${item.msg}.png`); setOpen(true); }}
                              onLoad={() => handleImageLoad(`${process.env.REACT_APP_BACKEND_URL}/media/${item.msg}.png`)}
                            />

                          </>
                        }
                        {item.type === 'sticker' &&
                          <> {item.c_read === 1 && <span className='text-xs pr-1 text-gray-400'>Read</span>}
                            <span className='rounded-xl inline-block max-w-xs my-1 chat-bubble whitespace-pre-wrap'>
                              {<img className='rounded aspect-square inline-block object-contain max-w-xs w-28 relative z-10'
                                src={images[item.msg + '.png']}
                                alt={item.msg}
                                onLoad={handleImageLoad}
                              />}
                            </span>
                          </>
                        }
                      </div>
                    </div>
                  ) : (
                    <div className="message__chats" key={item.time + k} data-chat-id={item.id}>
                      <div className='message__recipient flex items-center'>
                        <div className="w-14">
                          {//k > 0 && convertDateToThai(item.time) !== convertDateToThai(oldMsg.data[k - 1].time) &&
                            k > 0 && oldMsg.data[k - 1].admin &&
                            <Avatar alt={activeChat.name}
                              src={activeChat.source === 1 ? activeChat.avatar : `${process.env.REACT_APP_BACKEND_URL}/image/${activeChat.avatar}/40`}
                            />
                          }
                        </div>
                        <div className='relative flex items-center flex-wrap'>
                          <div className=''>
                            {item.reply && <div className='bg-slate-100 py-2 px-3 rounded-xl max-w-md my-1 chat-bubble whitespace-pre-wrap chat-text-box -mb-2 italic text-gray-600 w-full'>{item.reply}</div>}

                            {item.type === 'text' &&
                              <span
                                className='bg-slate-200 py-2 px-3 rounded-xl inline-block w-52 lg:w-auto max-w-md my-1 chat-bubble whitespace-pre-wrap chat-text-box'
                                dangerouslySetInnerHTML={{ __html: autoAddAhrefTag(item.msg) }}
                              >
                              </span>
                            }

                            {item.type === 'image' && activeChat.source === 1 &&

                              <img
                                className={imageClass}
                                src={`${process.env.REACT_APP_BACKEND_URL}/image/${item.msg}.jpg/350`}
                                alt={item.msg}
                                onClick={() => { setPopImage(`${process.env.REACT_APP_BACKEND_URL}/media/${item.msg}.jpg`); setOpen(true); }}
                                onLoad={() => handleImageLoad(`${process.env.REACT_APP_BACKEND_URL}/image/${item.msg}.jpg/350`)}
                                width={imageDimensions[`${process.env.REACT_APP_BACKEND_URL}/image/${item.msg}.jpg/350`]?.width}
                                height={imageDimensions[`${process.env.REACT_APP_BACKEND_URL}/image/${item.msg}.jpg/350`]?.height}
                                effect={"blur"}
                              />

                            }

                            {item.type === 'image' && activeChat.source === 2 &&

                              <img
                                className={imageClass}
                                src={`${process.env.REACT_APP_BACKEND_URL}/image/${item.msg}/350`}
                                alt={item.msg}
                                onClick={() => { setPopImage(`${process.env.REACT_APP_BACKEND_URL}/media/${item.msg}`); setOpen(true); }}
                                onLoad={() => handleImageLoad(`${process.env.REACT_APP_BACKEND_URL}/image/${item.msg}/350`)}
                                width={imageDimensions[`${process.env.REACT_APP_BACKEND_URL}/image/${item.msg}/350`]?.width}
                                height={imageDimensions[`${process.env.REACT_APP_BACKEND_URL}/image/${item.msg}/350`]?.height}
                                effect={"blur"}
                              />

                            }

                            {item.type === 'sticker' && activeChat.source === 1 &&
                              <img className='rounded aspect-square inline-block object-contain max-w-xs w-28 relative z-10'
                                src={`https://stickershop.line-scdn.net/stickershop/v1/sticker/${item.msg}/ANDROID/sticker.png`}
                                alt={item.msg}
                                onLoad={handleImageLoad}
                              />
                            }

                            {item.type === 'sticker' && activeChat.source === 2 &&
                              <span className='py-2 px-2 rounded-xl inline-block max-w-xs my-1 chat-bubble whitespace-pre-wrap'>
                                <img className='rounded aspect-square inline-block object-contain max-w-xs relative z-10 w-max sticker-image'
                                  src={`${process.env.REACT_APP_BACKEND_URL}/media/${item.msg}`}
                                  alt={item.msg}
                                  onLoad={handleImageLoad}
                                />
                              </span>
                            }

                            {item.type === 'video' && <VideoThumbnail videoFile={item.msg} />}

                            {item.type === 'audio' &&
                              <audio controls className='rounded-3xl my-1'>
                                <source src={item.msg} type="audio/mp4" onLoad={handleImageLoad} />
                              </audio>
                            }

                            {item.type === 'file' &&
                              <div className='bg-slate-200 py-2 px-3 rounded-xl inline-block max-w-md my-1 chat-bubble whitespace-pre-wrap chat-text-box'>
                                <a href={`${process.env.REACT_APP_BACKEND_URL}/file/download/${item.msg}`} download >{item.msg}</a>
                              </div>
                            }

                          </div>

                          <span className='block lg:inline-block text-xs text-slate-500 pl-2'>{convertDateToThai(item.time)}</span>
                        </div>
                      </div>
                    </div>
                  )
                ))
              }

              {Object.entries(messages).map(([k, message]) => (
                message.admin ? (
                  message.client && message.client === activeChat.chatID && (
                    <div className="message__chats" key={k}>
                      <p className='sender__name text-right block mt-2 mb-0.5'>{message.name === localStorage.getItem('name') ? '' : message.name}</p>
                      <div className='message__sender text-right'>
                        {message.type === 'text' &&
                          <>
                            {shouldDisplayReadLabel(message.time, readState[activeChat.chatID]?.time) ? <span className='text-xs pr-1 text-gray-400'>Read</span> : ''}

                            <span
                              className='bg-indigo-800 text-white py-2 px-3 rounded-xl inline-block max-w-md text-left whitespace-pre-wrap chat-text-box'
                              dangerouslySetInnerHTML={{ __html: autoAddAhrefTag(message.msg) }}
                            >
                            </span>
                          </>
                        }
                        {message.type === 'image' &&
                          <>
                            {shouldDisplayReadLabel(message.time, readState[activeChat.chatID]?.time) ? <span className='text-xs pr-1 text-gray-400'>Read</span> : ''}
                            <img className={imageClass}
                              src={`${process.env.REACT_APP_BACKEND_URL}/media/${message.msg}.png`}
                              alt={message.msg}
                              onClick={() => { setPopImage(`${process.env.REACT_APP_BACKEND_URL}/media/${message.msg}.png`); setOpen(true); }}
                              onLoad={handleImageLoad}
                            />
                          </>
                        }
                        {message.type === 'sticker' && activeChat.source === 1 &&
                          <>
                            {shouldDisplayReadLabel(message.time, readState[activeChat.chatID]?.time) ? <span className='text-xs pr-1 text-gray-400'>Read</span> : ''}
                            <img className='rounded aspect-square object-cover inline-block max-w-s w-32 relative z-10'
                              src={images[message.msg + '.png']}
                              alt={message.msg}
                              onLoad={handleImageLoad}
                            />
                          </>
                        }
                      </div>
                    </div>
                  )
                ) : (
                  message.user === activeChat.chatID && (
                    <div className="message__chats" key={k}>
                      <div className='message__recipient lg:flex items-center'>
                        <div className="w-14">
                          {k > 0 && messages[k - 1].admin &&
                            <Avatar alt={activeChat.name}
                              //src={activeChat.avatar} 
                              src={activeChat.source === 1 ? activeChat.avatar : `${process.env.REACT_APP_BACKEND_URL}/image/${activeChat.avatar}/40`}
                            />
                          }
                        </div>
                        <div className='relative flex items-center flex-wrap'>
                          <div>
                            {message.reply && <div className='bg-slate-100 py-2 px-3 rounded-xl max-w-md my-1 chat-bubble whitespace-pre-wrap chat-text-box -mb-2 italic text-gray-600 w-full'>{message.reply}</div>}

                            {message.type === 'text' &&


                              <span
                                className='bg-slate-200 py-2 px-3 rounded-xl inline-block max-w-md my-1 chat-bubble whitespace-pre-wrap chat-text-box'
                                dangerouslySetInnerHTML={{ __html: autoAddAhrefTag(message.msg) }}
                              >
                              </span>


                            }
                            {message.type === 'image' && activeChat.source === 1 &&
                              <img className={imageClass}
                                src={`${process.env.REACT_APP_BACKEND_URL}/media/${message.msg}.jpg`}
                                alt={message.msg}
                                onClick={() => { setPopImage(`${process.env.REACT_APP_BACKEND_URL}/media/${message.msg}.jpg`); setOpen(true); }}
                                onLoad={handleImageLoad}
                              />
                            }
                            {message.type === 'image' && activeChat.source === 2 &&

                              <img className={imageClass}
                                src={message.msg}
                                alt={message.msg}
                                onClick={() => { setPopImage(message.msg); setOpen(true); }}
                                onLoad={handleImageLoad}
                              />

                            }
                            {message.type === 'sticker' && activeChat.source === 1 &&
                              <img className='rounded aspect-square object-contain inline-block max-w-xs w-28 relative z-10'
                                src={`https://stickershop.line-scdn.net/stickershop/v1/sticker/${message.msg}/ANDROID/sticker.png`}
                                alt={message.msg}
                                onLoad={handleImageLoad}
                              />
                            }
                            {message.type === 'sticker' && activeChat.source === 2 &&
                              <span className='py-2 px-2 rounded-3xl inline-block max-w-xs my-1 chat-bubble whitespace-pre-wrap'>
                                <img className='rounded aspect-square object-contain inline-block max-w-md relative z-10 w-max'
                                  src={message.msg}
                                  alt={message.msg}
                                  onLoad={handleImageLoad}
                                />
                              </span>
                            }
                            {message.type === 'video' &&
                              <video width="320" height="240" controls className='my-1 rounded-xl aspect-video bg-gray-500' onLoad={handleImageLoad}>
                                <source src={message.msg} />
                              </video>
                            }
                            {message.type === 'audio' &&
                              <audio controls className='rounded-3xl my-1'>
                                <source src={message.msg} type="audio/mp4" onLoad={handleImageLoad} />
                              </audio>
                            }
                            {message.type === 'file' &&
                              <div className='bg-slate-200 py-2 px-3 rounded-xl inline-block max-w-md my-1 chat-bubble whitespace-pre-wrap chat-text-box'>

                                <a href={`${process.env.REACT_APP_BACKEND_URL}/file/download/${message.msg}`} download>{message.msg}</a>
                              </div>
                            }
                          </div>
                          <span className='text-xs text-slate-500 pl-2'>{message.time}</span>
                        </div>
                      </div>
                    </div>
                  )
                )
              ))}

              {uploadMediaSkt &&
                <div className="message__chats flex justify-end w-full mt-2">
                  <Skeleton variant="rounded" width={320} height={200} />
                </div>
              }
              {sendMsgSkt.display &&
                <div className="message__chats">
                  {/*<Skeleton variant="rounded" width={sendMsgSkt.width} height={40} />*/}

                  <div className='message__sender text-right'>
                    <span
                      className='bg-indigo-800 text-white py-2 px-3 rounded-xl inline-block max-w-md text-left whitespace-pre-wrap chat-text-box'
                      dangerouslySetInnerHTML={{ __html: autoAddAhrefTag(sendMsgSkt.msg) }}
                    >
                    </span>
                  </div>

                </div>
              }

              <div ref={lastMessageRef} />
            </div>
          </div>
        </div>
      </div>
      <Dialog
        maxWidth={'xl'}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
            position: "inherit"
          },
        }}
      >
        <DialogContent className='flex justify-center items-center bg-transparent'>
          <button onClick={handleClose} className='absolute top-1 right-1'>
            <CloseOutlinedIcon sx={{ color: '#fff', fontSize: 40 }} />
          </button>
          <img style={{ maxHeight: '85vh' }}
            src={popImage}
            alt={popImage}
          />
        </DialogContent>
      </Dialog>
    </>
  )
}

export default ChatBody