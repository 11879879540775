
import MainLayout from "../layout/mainLayout";

export default function Setting() {

    const CreateTable = () => {


        return (
            <>
               
            </>
        );
    }

    return (
        <MainLayout
            pageTitle='การตั้งค่า'
            disableFrom={true}
            pageContent={
                <CreateTable />
            }
        />
    );
}