import { useState, forwardRef } from 'react';
import axios from 'axios';
import DialogContent from '@mui/material/DialogContent';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import DialogActions from '@mui/material/DialogActions';
import QrCodeOutlinedIcon from '@mui/icons-material/QrCodeOutlined';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import InputAdornment from '@mui/material/InputAdornment';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Chip from '@mui/material/Chip';

import { createCanvas } from 'canvas';
import QRCode from 'qrcode';

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CreateQrCodePromptPay = ({ popImage, setPopImage, uploadFile }) => {

    const [open, setOpen] = useState(false);
    const [amount, setAmount] = useState(0);
    const [alertObj, setAlertObj] = useState({ open: false, message: '', severity: 'success' });
    const [loading, setLoading] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const getQrCodePayload = async () => {
        if (amount <= 0) {
            setAlertObj({
                open: true,
                message: 'กรุณาใส่จำนวนเงินที่ต้องการชำระ',
                severity: 'error'
            });
            return;
        }

        //setLoading(true);

        axios({
            method: 'get',
            url: `${process.env.REACT_APP_SHOP_API_URL}/app-admin/create-qrcode/${amount}`,
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            }
        }).then(async (res) => {

            const qrCodePayload = res.data.qrCodePayload;
            const canvas = createCanvas(800, 1200);
            const ctx = canvas.getContext('2d');
            const qrCanvas = createCanvas(200, 200);
            ctx.fillStyle = '#fff';
            ctx.fillRect(0, 0, canvas.width, canvas.height);

            const promptPayLogo = new Promise((resolve, reject) => {
                const logo = new Image();
                logo.src = '/ThaiQR-goplugin.png';
                logo.onload = () => {
                    const logoX = (canvas.width - 800) / 2;
                    const logoY = 0;
                    ctx.drawImage(logo, logoX, logoY, 800, 250);
                    resolve();
                }
                logo.onerror = reject;
            });

            promptPayLogo.then(() => {

                const drawQrCode = new Promise((resolve, reject) => {
                    QRCode.toCanvas(qrCanvas, qrCodePayload, {
                        width: 800,
                        margin: 5,
                        errorCorrectionLevel: 'H'
                    }, function (error) {
                        if (error) reject(error);
                        else resolve();
                    });
                });

                drawQrCode.then(() => {
                    const qrX = (canvas.width - qrCanvas.width) / 2;
                    const qrY = (canvas.height - qrCanvas.height) / 2 + 50;
                    ctx.drawImage(qrCanvas, qrX, qrY);


                    const drawLogo = new Promise((resolve, reject) => {
                        const logo = new Image();
                        logo.src = '/xfav.png';
                        logo.onload = () => {
                            const logoSize = 150;
                            const logoX = (canvas.width - logoSize) / 2;
                            const logoY = (canvas.height - logoSize) / 2 + 50;
                            ctx.drawImage(logo, logoX, logoY, logoSize, logoSize);
                            resolve();
                        };
                        logo.onerror = reject;
                    });

                    drawLogo.then(() => {
                        ctx.font = `35px 'Noto Sans Thai', sans-serif`;
                        const formattedAmount = amount.toLocaleString();
                        const text = `แสกน QR Code ด้วยแอพพลิเคชั่นธนาคาร\nยอดชำระ ${formattedAmount} บาท`;
                        const textY = canvas.height - 135;
                        ctx.fillStyle = 'black';
                        const lines = text.split('\n');
                        const lineHeight = 50;
                        for (let i = 0; i < lines.length; i++) {
                            const lineWidth = ctx.measureText(lines[i]).width;
                            const lineX = (canvas.width - lineWidth) / 2;
                            if (i === lines.length - 1) { // If this is the last line
                                ctx.font = `600 35px 'Noto Sans Thai', sans-serif`; // Set font weight to 600
                            }
                            ctx.fillText(lines[i], lineX, textY + i * lineHeight);
                            if (i === lines.length - 1) { // Reset the font weight after drawing the last line
                                ctx.font = `35px 'Noto Sans Thai', sans-serif`;
                            }
                        }

                        const base64Image = canvas.toDataURL();
                        //console.log(base64Image);
                        uploadFile(base64Image, 'qr')
                        setLoading(false);
                        setOpen(false);
                        setAmount(0);

                    }).catch((error) => {
                        console.error(error);
                        setAlertObj({
                            open: true,
                            message: 'เกิดข้อผิดพลาดในการสร้าง QR Code ชำระเงิน กรุณาลองใหม่อีกครั้ง',
                            severity: 'error'
                        });
                    });

                }).catch((error) => {
                    console.error(error);
                    setAlertObj({
                        open: true,
                        message: 'เกิดข้อผิดพลาดในการสร้าง QR Code ชำระเงิน กรุณาลองใหม่อีกครั้ง',
                        severity: 'error'
                    });
                });


            }).catch((error) => {
                console.error(error);
                setAlertObj({
                    open: true,
                    message: 'เกิดข้อผิดพลาดในการสร้าง QR Code ชำระเงิน กรุณาลองใหม่อีกครั้ง',
                    severity: 'error'
                });
            });

        }).catch((err) => {
            setAlertObj({
                open: true,
                message: 'เกิดข้อผิดพลาดในการสร้าง QR Code ชำระเงิน กรุณาลองใหม่อีกครั้ง',
                severity: 'error'
            });
        });
    }

    return (
        <>
            <Tooltip title={<span style={{ fontSize: '16px' }}>สร้าง QR Code ชำระเงิน</span>} placement="top" arrow>
                <button type='button' className="p-2" onClick={() => setOpen(true)}>
                    <QrCodeOutlinedIcon />
                </button>
            </Tooltip>

            <Dialog
                maxWidth={'sm'}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        position: "inherit"
                    },
                }}
            >
                <DialogTitle id="alert-dialog-title">
                    <div className='flex justify-between items-center'>
                        <div className='text-xl font-semibold'>สร้าง QR Code ชำระเงิน</div>
                        <button type='button' className='p-2' onClick={handleClose}>
                            <CloseOutlinedIcon />
                        </button>
                    </div>
                </DialogTitle>
                <DialogContent className='flex-col justify-center items-center bg-transparent border-y'>
                    <div className='flex justify-center items-center py-4'>
                        <TextField
                            label="จำนวนเงิน"
                            variant="outlined"
                            type="number"
                            value={amount}
                            onChange={(e) => setAmount(Number(e.target.value))}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">฿</InputAdornment>,
                            }}
                            sx={{ width: '300px', maxWidth: '100%' }}
                        />
                    </div>
                    <div className='flex justify-center items-center gap-2'>
                        <Chip label="50" variant="outlined" onClick={() => setAmount(50)} />
                        <Chip label="200" variant="outlined" onClick={() => setAmount(200)} />
                        <Chip label="500" variant="outlined" onClick={() => setAmount(500)} />
                        <Chip label="1,000" variant="outlined" onClick={() => setAmount(1000)} />
                        <Chip label="2,000" variant="outlined" onClick={() => setAmount(2000)} />
                    </div>
                </DialogContent>
                <DialogActions>
                    <div className='py-2 px-4 flex gap-6'>
                        <Button onClick={handleClose}>ยกเลิก</Button>
                        <Button variant="contained" onClick={getQrCodePayload} disabled={loading}>
                            {loading ? 'กำลังสร้าง...' : 'สร้าง QR Code'}
                        </Button>
                    </div>
                </DialogActions>
                <Snackbar open={alertObj.open} autoHideDuration={6000} onClose={() => setAlertObj({ ...alertObj, open: false })}>
                    <Alert onClose={() => setAlertObj({ ...alertObj, open: false })} severity={alertObj.severity} sx={{ width: '100%' }}>
                        {alertObj.message}
                    </Alert>
                </Snackbar>
            </Dialog>


        </>
    )
}

export default CreateQrCodePromptPay;