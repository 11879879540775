import React, { useEffect, useState, useRef } from 'react'
import { useParams, useNavigate } from "react-router-dom";
import ChatBody from '../components/ChatBody'
import ChatFooter from '../components/ChatFooter'
import axios from 'axios';
import Avatar from '@mui/material/Avatar';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import notiSound from "../assets/sounds/mixkit-long-pop-2358.wav";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import Chip from '@mui/material/Chip';
import Badge from '@mui/material/Badge';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import MenuList from '@mui/material/MenuList';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import CollectMoney from '../components/collectMoney'
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import ChatCustomerSkt from '../components/chatCustomerSkt'
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';

const SingleChatPage = ({ socket }) => {

    var { chatid } = useParams();
    const navigate = useNavigate();
    const audioRef = useRef(null);

    const [messages, setMessages] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [allCustomers, setAllCustomers] = useState(0);
    const [typingStatus, setTypingStatus] = useState("")
    const lastMessageRef = useRef(null);
    const [activeChat, setActiveChat] = useState({
        chatID: null,
        avatar: null,
        name: null,
        source: null,
        status: 1
    });
    const [searchCustomer, setSearchCustomer] = useState('');
    const customerOffset = useRef(0);

    const getNowTime = () => {
        const date = new Date();
        const hour = date.getHours().toString().padStart(2, '0');
        const minute = date.getMinutes().toString().padStart(2, '0');
        return hour + ':' + minute;
    }

    const [readState, setReadState] = useState({});

    useEffect(() => {

        const messageResponseHandler = data => {
            setMessages([...messages, data]);
            setCustomers(prevCustomers => {
                const existingCustomerIndex = prevCustomers.findIndex(c => c.user === data.user);
                if (existingCustomerIndex >= 0) {
                    const updatedCustomer = {
                        ...prevCustomers[existingCustomerIndex],
                        time: getNowTime(),
                        previewMsg: data.previewMsg
                    };
                    const newCustomers = [
                        updatedCustomer,
                        ...prevCustomers.slice(0, existingCustomerIndex),
                        ...prevCustomers.slice(existingCustomerIndex + 1)
                    ];
                    return newCustomers;
                }
                return prevCustomers;
            });
        }
        socket.on("messageResponse", messageResponseHandler);

        const lineWebhookResponseHandler = data => {
            if (data.c_read === true) {
                setReadState((prevReads) => ({
                    ...prevReads,
                    [data.user]: data,
                }));
                return;
            }
            setCustomers(prevCustomers => {
                const existingCustomerIndex = prevCustomers.findIndex(c => c.user === data.user);
                if (existingCustomerIndex >= 0) {
                    const updatedCustomer = { ...data };
                    const newCustomers = prevCustomers.filter(c => c.user !== data.user);
                    newCustomers.unshift(updatedCustomer);
                    return newCustomers;
                } else {
                    return [data, ...prevCustomers];
                }
            });
            setMessages([...messages, data]);
            if (audioRef.current.paused || audioRef.current.ended) {
                audioRef.current.play();
            }
        }
        socket.on("lineWebhookResponse", lineWebhookResponseHandler);

        const typingResponseHandler = data => {
            setTypingStatus(data)
        }
        socket.on("typingResponse", typingResponseHandler)

        const readHandler = data => {
            const updatedCustomers = customers.map((customer) =>
                customer.user === data.user ? { ...customer, read: 1 } : customer
            );
            setCustomers(updatedCustomers);
        }
        socket.on("read", readHandler);

        return () => {
            socket.off("messageResponse", messageResponseHandler);
            socket.off("lineWebhookResponse", lineWebhookResponseHandler);
            socket.off("typingResponse", typingResponseHandler);
            socket.off("read", readHandler);
        }

    }, [socket, messages, customers, readState]);


    useEffect(() => {
        if (messages.length < 1) {
            return;
        }
        const lastObject = messages[messages.length - 1];

        if (lastObject.hasOwnProperty('user') && lastObject.user === activeChat.chatID) {
            lastMessageRef.current?.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages, activeChat]);


    const fetchCustomer = (filter = null, reset = true) => {
        let filterX = '';
        if (filter !== null) {
            filterX = `&filter=${filter}`;
            setKeyword(filter);
        }
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}/chat/customers?offset=${customerOffset.current}${filterX}`,
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            },
        }).then(function (response) {
            if (response.data.status === 200) {
                if (reset === true) {
                    setCustomers(prevCustomers => [...prevCustomers, ...response.data.data]);
                } else {
                    setCustomers(response.data.data);
                }
                setAllCustomers(response.data.all);
            }
        });
    }

    const filterCustomers = (search) => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}/chat/customers?offset=0&search=${search}`,
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            },
        }).then(function (response) {
            if (response.data.status === 200) {
                setCustomers(response.data.data);
                setAllCustomers(response.data.all);
            }
        });
    }

    useEffect(() => {
        document.title = `Chat - ${process.env.REACT_APP_SITE_NAME}`;
        fetchCustomer();
        audioRef.current = new Audio(notiSound);
        audioRef.current.volume = 1;
        audioRef.current.crossOrigin = 'anonymous';
        localStorage.setItem('collapsed', 'collapsed');
        document.getElementById('main-side-bar').classList.add("collapsed");
    }, []);

    /*
    useEffect(() => {
        if (!chatid && customers.length > 0) {
            navigate(`/chat/${customers[0].user}`);
        }
    }, [chatid, customers, navigate])
    */

    const sendRead = (user) => {
        if (chatid === !activeChat.chatID) {
            setMessages([]);
        }
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_BACKEND_URL}/chat/read`,
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            },
            data: {
                user: user,
            }
        }).then(function (response) {
            socket.emit("read", {
                user: user,
                id: `${socket.id}${Math.random()}`,
                socketID: socket.id
            })
        });
    }

    const myDivRef = useRef(null);

    useEffect(() => {
        const myDiv = myDivRef.current;
        function handleScroll() {
            if (myDiv.scrollTop + myDiv.clientHeight === myDiv.scrollHeight) {
                if (customerOffset.current >= allCustomers) {
                    return;
                }
                customerOffset.current = customerOffset.current + 1;
                fetchCustomer();
            }
        }
        myDiv.addEventListener('scroll', handleScroll);
        return () => {
            myDiv.removeEventListener('scroll', handleScroll);
        };
    }, [allCustomers]);

    const handleImageError = (userID, social) => {
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_BACKEND_URL}/chat/avatar-error`,
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            },
            data: {
                userID: userID,
                social: social
            }
        }).then(function (response) {
            console.log(response.data);
        });
    }

    const [uploadMediaSkt, setUploadMediaSkt] = useState(false);
    const [sendMsgSkt, setSendMsgSkt] = useState({
        'display': false,
        'width': 210
    });
    const [open, setOpen] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const [notes, setnotes] = useState([]);

    const fetchNotes = () => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}/chat/note?client=${chatid}`,
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            },
        }).then(function (response) {
            if (response.data.status === 200) {
                setnotes(response.data.data);
            }
        });
    }

    const [clientOrder, setClientOrder] = useState([]);

    /*
    const fetchClientOrder = () => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}/chat/order?client=${chatid}`,
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            },
        }).then(function (response) {
            if (response.data.status === 200) {
                setClientOrder(response.data.data);
            }
        });
    }
    */

    useEffect(() => {
        if (chatid) {
            axios({
                method: 'get',
                url: `${process.env.REACT_APP_BACKEND_URL}/chat/note?client=${chatid}`,
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/json'
                },
            }).then(function (response) {
                if (response.data.status === 200) {
                    setnotes(response.data.data);
                }
            });

            axios({
                method: 'get',
                url: `${process.env.REACT_APP_BACKEND_URL}/chat/order?client=${chatid}`,
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/json'
                },
            }).then(function (response) {
                if (response.data.status === 200) {
                    setClientOrder(response.data.data);
                }
            });
        }
    }, [chatid]);

    const [noteInput, setNoteInput] = useState('');

    const saveNote = () => {
        setOpen(false)
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_BACKEND_URL}/chat/note`,
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            },
            data: {
                admin: localStorage.getItem("user"),
                client: chatid,
                note: noteInput,
            }
        }).then(function (response) {
            if (response.data.status === 200) {
                setNoteInput('');
                if (notes) {
                    setnotes([response.data.data, ...notes]);
                } else {
                    setnotes([response.data.data]);
                }
            }
        });
    }

    const [anchorEl, setAnchorEl] = useState(null);
    const openFilter = Boolean(anchorEl);
    const handleClickFilter = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [editNoteID, setEditNoteID] = useState(null);
    const editNote = () => {
        if (editNoteID === null) {
            return;
        }
        axios({
            method: 'PUT',
            url: `${process.env.REACT_APP_BACKEND_URL}/chat/note/${editNoteID}`,
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            },
            data: {
                admin: localStorage.getItem("user"),
                client: chatid,
                note: noteInput,
            }
        }).then(function (response) {
            if (response.data.status === 200) {
                setNoteInput('');
                setEditNoteID(null);
                fetchNotes();
            }
        });
    }

    const [openDelete, setOpenDelete] = useState(false);
    const [deleteID, setDeleteID] = useState(null);

    const deleteNote = () => {
        if (deleteID === null) {
            return;
        }
        axios({
            method: 'DELETE',
            url: `${process.env.REACT_APP_BACKEND_URL}/chat/note/${deleteID}`,
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            },
            data: {
                admin: localStorage.getItem("user"),
                client: chatid,
                note: noteInput,
            }
        }).then(function (response) {
            if (response.data.status === 200) {
                setDeleteID(null);
                fetchNotes();
            }
        });
    }

    const updateStatus = (status) => {
        axios({
            method: 'PUT',
            url: `${process.env.REACT_APP_BACKEND_URL}/chat/status/${chatid}`,
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            },
            data: {
                status: status,
            }
        }).then(function (response) {
            if (response.data.status === 200) {
                setActiveChat(prev => ({
                    ...prev,
                    status: status
                }));
            }
        });
    }


    const [searchMsgID, setSearchMsgID] = useState(null);
    const [showUserlist, setShowUserList] = useState(true);


    const [showSingleSearch, setShowSingleSearch] = useState(false);
    const [keyword, setKeyword] = useState('');
    const [foundMessages, setFoundMessages] = useState([]);
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const [showNotfound, setShowNotFound] = useState(false);

    const HandleClickCustomer = () => {
    
    }
    return (
        <>
            <div className="app-layout-modern ">
                <div className="flex flex-row max-h-fit">
                    <div
                        className={`lg:block w-full lg:w-2/12 max-h-screen overflow-y-scroll sc-white bg-white ${showUserlist ? '' : 'hidden'}`}
                        ref={myDivRef} style={{ minWidth: '320px' }}
                    >
                        <div className={`flex justify-between items-center px-4 py-2.5 border-b-slate-400 sticky top-0 bg-white z-50 `}>
                            <div className='bg-slate-50 max-w-max'>
                                <IconButton sx={{ p: '10px' }} aria-label="menu" onClick={handleClickFilter}>
                                    <MenuIcon />
                                </IconButton>
                                <InputBase
                                    sx={{ ml: 1, flex: 1 }}
                                    placeholder="ค้นหา"
                                    inputProps={{ 'aria-label': 'ค้นหา' }}
                                    value={searchCustomer}
                                    onChange={(e) => {
                                        let long = e.target.value;
                                        if (long.length === 0) {
                                            customerOffset.current = 0;
                                            setCustomers([]);
                                            fetchCustomer();
                                        }
                                        setSearchCustomer(e.target.value)
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            filterCustomers(searchCustomer)
                                        }
                                    }}
                                />
                                <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={() => filterCustomers(searchCustomer)}>
                                    <SearchIcon />
                                </IconButton>
                            </div>
                        </div>

                        <Menu
                            anchorEl={anchorEl}
                            open={openFilter}
                            onClose={handleClose}

                        >
                            <Paper elevation={0} sx={{ width: 285, maxWidth: '100%' }}>

                                <MenuList>
                                    <MenuItem onClick={() => { handleClose(); fetchCustomer(4, false) }}>
                                        <ListItemText>ทั้งหมด</ListItemText>
                                    </MenuItem>

                                    <MenuItem onClick={() => { handleClose(); fetchCustomer(1, false) }}>
                                        <ListItemText>ยังไม่ได้ตอบรับ</ListItemText>
                                    </MenuItem>

                                    <MenuItem onClick={() => { handleClose(); fetchCustomer(2, false) }}>
                                        <ListItemText>กำลังดำเนินการ</ListItemText>
                                    </MenuItem>

                                    <MenuItem onClick={() => { handleClose(); fetchCustomer(3, false) }}>
                                        <ListItemText>เรียบร้อยแล้ว</ListItemText>
                                    </MenuItem>

                                </MenuList>
                            </Paper>
                        </Menu>



                        {customers.length > 0 ? Object.entries(customers).map(([k, item]) => (
                            <div
                                className={`flex py-4 px-4 cursor-pointer transition-colors hover:bg-slate-100 ${chatid === item.user ? 'bg-slate-100' : ''}`}
                                key={k}
                                onClick={() => {
                                    HandleClickCustomer();
                                    //setMessages([]);
                                    // if chat not active not set msg or set []
                                    /*
                                    setActiveChat(prev => ({
                                        ...prev,
                                        chatID: item.user,
                                        avatar: item.avatar,
                                        name: item.name,
                                        source: item.source
                                    }));
                                    */
                                    //setMessages([]);
                                    /*
                                    setActiveChat(prev => ({
                                        ...prev,
                                        avatar: null,
                                        name: null,
                                    }));
                                    */
                                    if (item.read === 0) {
                                        /*
                                        if (activeChat.chatID !== item.user) {
                                            sendRead(item.user);
                                        }
                                        */
                                        sendRead(item.user);
                                    }


                                    if (item.id) {
                                        setSearchMsgID(item.id)
                                    }

                                    setKeyword('');
                                    setFoundMessages([]);
                                    setIsDropdownVisible(false);
                                    setShowNotFound(false);
                                    setShowSingleSearch(false);


                                    navigate(`/chat/${item.user}`, {
                                        state: {
                                            customers: customers,

                                        }
                                    })

                                    setShowUserList(false);
                                }}
                            >
                                <div className="w-2/12">
                                    <Badge
                                        overlap="circular"
                                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                        badgeContent={item.source === 1 ?
                                            <Avatar alt='Line icon' sx={{ width: 18, height: 18, bgcolor: '#00B900' }}></Avatar> :
                                            <Avatar alt='facebook icon' sx={{ width: 18, height: 18, bgcolor: '#0a7cff' }}></Avatar>
                                        }
                                    >
                                        <Avatar alt={item.name}

                                            src={item.source === 1 ? item.avatar : `${process.env.REACT_APP_BACKEND_URL}/image/${item.avatar}/45`}
                                            sx={{ width: 45, height: 45 }} onError={() => handleImageError(item.user, item.source)} />
                                    </Badge>
                                </div>
                                <div className='w-10/12'>
                                    <div className='flex'>
                                        <div className='w-9/12'>
                                            <h4 className='truncate px-3'>{item.status === 2 && (<span className='text-red-700'>! </span>)}{item.name}</h4>
                                            <div className='text-sm text-slate-500 truncate px-3'>
                                                {typingStatus.typeToClient === item.user ? typingStatus.typeMsg : item.previewMsg || item.msg}
                                            </div>
                                        </div>
                                        <div className='w-3/12 text-right h-full'>
                                            <div className='text-xs text-slate-500'>{item.time}</div>
                                            {item.read === 0 &&
                                                <div className={`w-full flex justify-end items-end mt-2 read-dot-${item.user}`}>
                                                    <div className='w-2.5 h-2.5 rounded-full bg-indigo-600'></div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )) : <ChatCustomerSkt />}
                    </div>
                    {!chatid ?
                        <div className={`w-10/12 relative overflow-x-hidden px-7 py-5 mb-auto lg:flex justify-center items-center h-screen bg-slate-50 ${showUserlist ? 'hidden' : ''}`}>
                            <div className='table-responsive py-7 min-h-80 text-center'>
                                <ForumOutlinedIcon sx={{ color: '#9ca3af', fontSize: 120 }} />
                                <h1 className='text-2xl font-bold text-gray-400'>แชทเลย!</h1>
                            </div>
                        </div>
                        :
                        <>
                            <div className={`lg:flex w-full md:w-8/12 flex-col flex-auto min-h-screen min-w-0 relative bg-main-bg-color justify-between max-h-screen bg-slate-50 ${showUserlist ? 'hidden' : ''}`}>
                                <div className='flex flex-wrap items-center justify-between sticky top-0 bg-white z-50'>
                                    <div className='flex gap-4 justify-between items-center py-2 px-6  w-full bg-white'>
                                        <div className='flex gap-2 items-center'>
                                            {/*<h1 className='text-xl font-bold'>{activeChat.name}</h1>*/}
                                            <button className='text-gray-500' onClick={() => {
                                                navigate(`/chat`);
                                                setShowUserList(true);
                                                setShowSingleSearch(false);
                                            }}>
                                                <ArrowBackOutlinedIcon />
                                            </button>
                                            <Chip label={activeChat.source === 1 ? 'Line' : 'Facebook'} variant="outlined" />

                                        </div>
                                        <div className='flex justify-end items-center gap-2'>
                                            <div className='flex items-center gap-2'>
                                                <FormControl className='bg-slate-100 rounded status-select' variant="standard" sx={{ m: 1, minWidth: 150 }}>
                                                    <Select
                                                        value={activeChat.status}
                                                        className='px-4'
                                                        onChange={(e) => updateStatus(e.target.value)}
                                                    >
                                                        <MenuItem value={1}>ยังไม่ได้ตอบรับ</MenuItem>
                                                        <MenuItem value={2}>ต้องดำเนินการ</MenuItem>
                                                        <MenuItem value={3}>เรียบร้อยแล้ว</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            <button className='ml-4' onClick={() => setShowSingleSearch(!showSingleSearch)}>
                                                <SearchOutlinedIcon />
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <ChatBody
                                    messages={messages}
                                    typingStatus={typingStatus}
                                    lastMessageRef={lastMessageRef}
                                    activeChat={activeChat}
                                    setActiveChat={setActiveChat}
                                    chatid={chatid}
                                    uploadMediaSkt={uploadMediaSkt}
                                    sendMsgSkt={sendMsgSkt}
                                    setMessages={setMessages}
                                    searchMsgID={searchMsgID}
                                    readState={readState}
                                    showSingleSearch={showSingleSearch}
                                    setShowSingleSearch={setShowSingleSearch}
                                    keyword={keyword}
                                    setKeyword={setKeyword}
                                    foundMessages={foundMessages}
                                    setFoundMessages={setFoundMessages}
                                    isDropdownVisible={isDropdownVisible}
                                    setIsDropdownVisible={setIsDropdownVisible}
                                    showNotfound={showNotfound}
                                    setShowNotFound={setShowNotFound}
                                
                                  
                                />

                                <div className='sticky bottom-0 bg-slate-50 z-50'>
                                    {typingStatus.typeToClient === activeChat.chatID &&
                                        <p className='absolute left-6 -top-2'> {typingStatus.typeMsg}</p>
                                    }
                                    <ChatFooter
                                        socket={socket}
                                        activeChat={activeChat}
                                        socialType={activeChat.source}
                                        uploadMediaSkt={uploadMediaSkt}
                                        setUploadMediaSkt={setUploadMediaSkt}
                                        setSendMsgSkt={setSendMsgSkt}
                                        sendRead={sendRead}

                                    />
                                </div>
                            </div>
                            <div className="hidden md:block w-2/12 max-h-screen overflow-y-scroll bg-white" style={{ minWidth: '300px' }}>
                                {activeChat.chatID !== null &&
                                    <>
                                        <div className='mt-10 mb-5 flex w-full justify-center flex-wrap relative'>
                                            <div className='absolute top-0 right-2'>
                                                <button>
                                                    <MoreVertOutlinedIcon />
                                                </button>
                                            </div>
                                            <div className='w-full flex justify-center'>
                                                <Avatar alt={activeChat.name}
                                                    src={activeChat.source === 1 ? activeChat.avatar : `${process.env.REACT_APP_BACKEND_URL}/image/${activeChat.avatar}/175`}
                                                    sx={{ width: 175, height: 175 }} />
                                            </div>
                                            <div className='max-w-full px-4 flex items-center'>
                                                <h3 className='my-4 text-lg font-bold text-center text-gray-600'>{activeChat.name}</h3>
                                            </div>
                                        </div>
                                        <div className='border-t p-4'>
                                            <div className='flex justify-between mb-4'>
                                                <h5>บันทึก</h5>
                                                <button>
                                                    <AddOutlinedIcon onClick={handleClickOpen} />
                                                </button>
                                            </div>
                                            <div>
                                                {notes && Object.entries(notes).map(([k, item]) => (
                                                    <div className='border p-4 my-2 rounded' key={k}>
                                                        <div className='flex justify-between'>
                                                            <div className='text-sm text-gray-500'>{item.date}</div>
                                                            <div>
                                                                <button className='ml-4' onClick={() => {
                                                                    setEditNoteID(item.id);
                                                                    setNoteInput(item.text);
                                                                    setOpen(true);
                                                                }}>
                                                                    <CreateOutlinedIcon sx={{ fontSize: '20px' }} />
                                                                </button>
                                                                <button className='ml-1' onClick={() => {
                                                                    setDeleteID(item.id);
                                                                    setOpenDelete(true);
                                                                }}>
                                                                    <DeleteOutlineOutlinedIcon sx={{ fontSize: '20px' }} />
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div className='whitespace-pre-wrap chat-text-box'>{item.text}</div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                        <div className='border-t p-4'>
                                            <div className='flex justify-between items-center mb-4'>
                                                <h5>รายการสั่งซื้อ</h5>
                                                <CollectMoney customerx={activeChat.chatID} clientOrder={clientOrder} setClientOrder={setClientOrder} />
                                            </div>
                                            <div>
                                                {clientOrder && Object.entries(clientOrder).map(([k, item]) => (
                                                    <div className='border p-4 my-2 rounded' key={k}>
                                                        <div className='flex justify-between'>
                                                            <div className='text-sm '>#{item.id} ({item.total} <span className='text-xs'>฿</span>)</div>
                                                            <div className='text-sm '>{item.date}</div>
                                                        </div>
                                                        <div className='mt-2'>
                                                            {Object.entries(item.products).map(([kk, itm]) => (
                                                                <div className='flex justify-between items-baseline' key={kk}>
                                                                    <div className='text-sm text-gray-500 truncate'>{itm.productDescription}</div>
                                                                    <div className='text-xs text-gray-500'>x{itm.qty}</div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                        </>
                    }

                </div>


            </div>


            <Dialog
                fullWidth={true}
                open={open}
                onClose={() => setOpen(false)}
                maxWidth={'sm'}
            >
                <DialogTitle>เขียนบันทึก</DialogTitle>
                <DialogContent>
                    <div className='pt-1.5'>
                        <TextField
                            label="ข้อความ"
                            multiline
                            rows={5}
                            className='w-full'
                            value={noteInput}
                            onChange={(e) => setNoteInput(e.target.value)}
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setOpen(false);
                        setNoteInput('');
                        setEditNoteID(null);
                    }}>ยกเลิก</Button>
                    <Button
                        onClick={() => {
                            if (editNoteID === null) {
                                saveNote();
                            } else {
                                editNote();
                            }
                            setOpen(false);
                        }}
                        autoFocus>
                        บันทึก
                    </Button>
                </DialogActions>
            </Dialog>


            <Dialog
                fullWidth={true}
                open={openDelete}
                onClose={() => setOpenDelete(false)}
                maxWidth={'sm'}
            >
                <DialogTitle>ยืนยันการลบข้อมูล</DialogTitle>
                <DialogContent>
                    เมื่อลบแล้วจะไม่สามารถกู้คืนได้
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setDeleteID(null);
                        setOpenDelete(false);
                    }}>ยกเลิก</Button>
                    <Button
                        onClick={() => {
                            deleteNote();
                            setOpenDelete(false);
                        }}
                        autoFocus>
                        ยืนยัน
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default SingleChatPage