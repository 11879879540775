import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
//import { Navigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import PrivateRoute from "./modules/useAuth";
import Login from "./pages/login";
import Logout from "./pages/logout";
//import ChatPage from "./pages/chat";
import Dashboard from "./pages/dashboard";
import socketIO from "socket.io-client";
import SingleChatClientPage from "./pages/singleChatPage";
//import Products from "./pages/products";
import Leads from "./pages/leads";
import Customers from "./pages/customers";
import Setting from "./pages/setting";
import Users from "./pages/users";
import Report from "./pages/report";
import Task from "./pages/task";
import ToastNotification from "./components/toastNotification";
import ProductEditor from "./pages/productEditor"
import Orders from "./pages/orders";
import FileStore from "./pages/fileStore";
import TempProducts from "./pages/tempProducts";
import OrderFromWeb from "./pages/orderFromWeb";

let socket = null
let token = process.env.REACT_APP_MY_TOKEN;
if (localStorage.getItem('token')) {
  token = localStorage.getItem('token');
  socket = socketIO.connect(process.env.REACT_APP_CHAT_URL, {
    query: { token }
  });
}


const theme = createTheme({
  typography: {
    fontFamily: ["Noto Sans Thai"].join(","),
  },
  palette: {
    primary: {
      light: "#6e5ad5",
      main: "#3730a3",
      dark: "#000973",
      contrastText: "#fff",
    },
    secondary: {
      light: "#fff154",
      main: "#febf12",
      dark: "#c68f00",
      contrastText: "#000",
    },
  },
});

function App() {

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route
            index
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />

          <Route
            path="/chat"
            element={
              <PrivateRoute>
                <SingleChatClientPage socket={socket} />
              </PrivateRoute>
            }
          />

          <Route
            path="/chat/:chatid"
            element={
              <PrivateRoute>
                <SingleChatClientPage socket={socket} />
              </PrivateRoute>
            }
          />



          <Route
            path="/leads"
            element={
              <PrivateRoute>
                <Leads />
              </PrivateRoute>
            }
          />
          <Route
            path="/customer"
            element={
              <PrivateRoute>
                <Customers />
              </PrivateRoute>
            }
          />
          {/*<Route
            path="/products"
            element={
              <PrivateRoute>
                <Products />
              </PrivateRoute>
            }
          />*/}
          <Route
            path="/product/add"
            element={
              <PrivateRoute>
                <ProductEditor />
              </PrivateRoute>
            }
          />

          <Route
            path="/orders"
            element={
              <PrivateRoute>
                <Orders />
              </PrivateRoute>
            }
          />

          <Route
            path="/order-from-web"
            element={
              <PrivateRoute>
                <OrderFromWeb />
              </PrivateRoute>
            }
          />


          <Route
            path="/task"
            element={
              <PrivateRoute>
                <Task />
              </PrivateRoute>
            }
          />
          <Route
            path="/report"
            element={
              <PrivateRoute>
                <Report />
              </PrivateRoute>
            }
          />
          <Route
            path="/setting"
            element={
              <PrivateRoute>
                <Setting />
              </PrivateRoute>
            }
          />
          <Route
            path="/user"
            element={
              <PrivateRoute>
                <Users />
              </PrivateRoute>
            }
          />
          <Route
            path="/files"
            element={
              <PrivateRoute>
                <FileStore />
              </PrivateRoute>
            }
          />
          <Route
            path="/products"
            element={
              <PrivateRoute>
                <TempProducts />
              </PrivateRoute>
            }
          />


          <Route
            path="/auth"
            element={
              <Login
              //socket={socket} 
              />
            }
          />
          <Route path="/logout" element={<Logout />} />

        </Routes>
      </Router>

      <ToastNotification socket={socket} eventName="pushNotification" />

    </ThemeProvider>
  );
}
export default App;
