import { useState, useEffect } from 'react';
import axios from 'axios';

export const useChatHelper = () => {
    const [helperList, setHelperList] = useState([]);
    useEffect(() => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}/chat/helper`,
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            },
        }).then(function (response) {
            setHelperList(response.data.data);
        });
    }, []);
    return helperList;
}