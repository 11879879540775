import Skeleton from '@mui/material/Skeleton';
export default function ChatCustomerSkt() {
    return (
        <div className="w-full md:w-2/12 max-h-screen overflow-hidden bg-white" style={{ minWidth: '310px' }}>
            {Array(20)
                .fill(1)
                .map((card, index) => (
                    <div className={`flex py-4 px-4 cursor-pointer transition-colors items-center hover:bg-slate-100`} key={index}>
                        <div className="w-2/12">
                            <Skeleton variant="circular" width={45} height={45} />
                        </div>
                        <div className='w-10/12'>
                            <div className='flex'>
                                <div className='w-9/12'>
                                    <h4 className='truncate px-3'>
                                        <Skeleton variant="rounded" width={100} height={15} />
                                        <div className='mt-1.5'>
                                            <Skeleton variant="rounded" width={140} height={15} />
                                        </div>
                                    </h4>
                                    <div className='text-sm text-slate-500 truncate px-3'>
                                        
                                    </div>
                                </div>
                                <div className='w-3/12 text-right h-full flex justify-end'>
                                    <Skeleton variant="rounded" width={30} height={15} />
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
        </div>
    );
}