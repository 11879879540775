import { useEffect, useState, forwardRef } from "react"
//import { useNavigate } from 'react-router-dom';
import logo from './../logo.svg';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import axios from 'axios';
import InputLabel from '@mui/material/InputLabel';
import IconButton from '@mui/material/IconButton';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import socketIO from "socket.io-client";

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Login({ socket }) {

    const [open, setOpen] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [disabled, setDisabled] = useState(false);
    //const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => { event.preventDefault(); };

    const submit = async () => {
        if (username === '') {
            setErrorMsg('กรุณากรอกชื่อผู้ใช้');
            setOpen(true);
            return;
        }
        if (password === '') {
            setErrorMsg('กรุณากรอกรหัสผ่าน');
            setOpen(true);
            return;
        }
        setDisabled(true);
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_BACKEND_URL}/auth`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: {
                username: username,
                password: password
            }
        }).then(async function (response) {
            if (response.data.status === 500) {
                setDisabled(false);
                setErrorMsg(response.data.msg);
                setOpen(true);
            }
            if (response.data.status === 200) {
                localStorage.setItem('token', response.data.token);
                localStorage.setItem('username', response.data.data.username);
                localStorage.setItem('name', response.data.data.name);
                localStorage.setItem('user', response.data.data.id);
                localStorage.setItem('noticount', 0);
                localStorage.setItem('collapsed', 'collapsed');
                var newUser = response.data.name;

                const tokenX = response.data.token;
                const socketX = await socketIO.connect(process.env.REACT_APP_CHAT_URL, {
                    query: { tokenX }
                });
                await socketX.emit("newUser", { newUser, socketID: socketX.id })

                const params = new Proxy(new URLSearchParams(window.location.search), {
                    get: (searchParams, prop) => searchParams.get(prop),
                });
                if (params.redirect) {
                    //navigate(params.redirect)
                    window.location.href = params.redirect
                } else {
                    //navigate('/');
                    window.location.href = '/'
                }
            }
        });
    }

    const handleClick = () => {
        setErrorMsg('ผู้ดูแลระบบไม่เปิดใช้การกู้รหัสผ่าน กรุณาติดต่อผู้ดูแลระบบ');
        setOpen(true);
    };
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    useEffect(() => {
        document.title = `Auth - ${process.env.REACT_APP_SITE_NAME}`;
    }, [])

    return (
        <>
            <div className="h-screen p-6 flex flex-wrap bg-white rounded-r-lg">
                <div className="logo-warp w-full">
                    <img src={logo} className="w-28" alt="logo" />
                </div>
                <div className="login-warp w-full flex justify-center">
                    <div className="w-full lg:w-1/4">
                        <h1 className="text-3xl font-bold mb-2">เข้าสู่ระบบ</h1>
                        <p className="mb-8">กรอกชื่อผู้ใช้ และรหัสผ่าน เพื่อเข้าสู่ระบบ</p>
                        <div className="mb-4 w-full">
                            <TextField fullWidth label="ชื่อผู้ใช้" variant="outlined" onKeyUp={(e) => setUsername(e.target.value)} />
                        </div>
                        <div className="mb-4 w-full">
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel htmlFor={'password'}>รหัสผ่าน</InputLabel>
                                <OutlinedInput
                                    id={'password'}
                                    type={showPassword ? 'text' : 'password'}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label={'รหัสผ่าน'}
                                    fullWidth
                                    variant="outlined"
                                    onKeyUp={(e) => setPassword(e.target.value)}
                                />
                            </FormControl>
                        </div>
                        <div className="flex justify-between items-center mb-4">
                            <FormControlLabel control={<Checkbox />} label="จดจำฉันไว้เป็นเวลา 30 วัน" />
                            <button className="text-indigo-800 mt-1" onClick={handleClick}>ลืมรหัสผ่าน ?</button>
                        </div>
                        <button className="w-full bg-indigo-800 text-white py-3 rounded-md" onClick={submit} disabled={disabled}>เข้าสู่ระบบ</button>
                    </div>
                </div>
                <div className="copy-right w-full flex justify-between items-end">
                    <p>© 2016-{new Date().getFullYear()} Infinity Content Co.,Ltd.</p>
                    <a href="https://8content.com" rel="noreferrer" target="_blank">8content.com</a>
                </div>
            </div>
            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                    {errorMsg}
                </Alert>
            </Snackbar>
        </>
    )
}