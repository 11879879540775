import { useState, useEffect, useRef, forwardRef, useCallback } from 'react';
import axios from 'axios';
import MainLayout from "../layout/mainLayout";
import Tooltip from '@mui/material/Tooltip';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function FileStore() {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const page = useRef(1);
    const search = useRef(null);
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertText, setAlertText] = useState({
        msg: '',
        type: 'error'
    });
    const searchText = useRef('');
    const tableRef = useRef(null);

    const observer = useRef();
    const lastFileElementRef = useCallback(node => {
        if (loading) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMore) {
                page.current++;
                loadFiles();
            }
        })
        if (node) observer.current.observe(node);
    }, [loading, hasMore]);

    useEffect(() => {
        loadFiles();
        document.title = `ไฟล์ - ${process.env.REACT_APP_SITE_NAME}`;
    }, []);


    const loadFiles = (search = '') => {
        setLoading(true);
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_SHOP_API_URL}/app-admin/files/${page.current}?search=${searchText.current}`,
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            }
        }).then(function (response) {
            if (response.status === 200) {
                setData(prevData => [...prevData, ...response.data.files]);
                setHasMore(response.data.files.length > 0);
                setLoading(false);
            }
        });
    }

    const submitSearch = (e) => {
        e.preventDefault();
        const inputValue = search.current.elements["top-search"].value;
        if (inputValue.trim() === "") {
            searchText.current = '';
            setData([]);
            page.current = 1;
            loadFiles();
            return;
        }
        searchText.current = inputValue;
        setData([]);
        page.current = 1;
        loadFiles(inputValue);
    }

    return (
        <>
            <MainLayout
                removePadding={true}
                pageContent={
                    <div id="real-main" className='flex flex-col justify-between px-6' ref={tableRef}>
                        <div className='flex justify-between items-center mt-4'>
                            <h1 className='font-bold text-xl '>ไฟล์</h1>
                        </div>
                        <div className='table-responsive mt-2 bg-white'>
                            <table className="table-auto w-full border-collapse border border-slate-150">
                                <thead>
                                    <tr>
                                        <th className='text-left border-b border-slate-150 p-4 pl-8 pt-3 pb-3 font-bold text-noto'>ชื่อ</th>
                                        <th className='text-left border-b border-slate-150 p-4 pl-8 pt-3 pb-3 font-bold text-noto'>แก้ไขล่าสุด</th>
                                        <th className='text-left border-b border-slate-150 p-4 pl-8 pt-3 pb-3 font-bold text-noto'>ขนาด</th>
                                        <th className='text-right border-b border-slate-150 p-4 pl-8 pr-6 pt-3 pb-3 font-bold text-noto'></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.length > 0 ? Object.entries(data).map(([k, item], index) => (
                                        <tr className='align-center border-b border-b-slate-150 hover:bg-slate-50' key={k} ref={index === data.length - 1 ? lastFileElementRef : null}>
                                            <td className='p-4 py-1 pl-8'>
                                                <h4 className=''>{item.file_name}</h4>
                                            </td>
                                            <td className='p-4 pl-8'>
                                                <p>{
                                                    new Date(item.update_at).toLocaleString('th-TH', {
                                                        year: 'numeric',
                                                        month: 'long',
                                                        day: 'numeric',
                                                        hour: 'numeric',
                                                        minute: 'numeric'
                                                    })
                                                }</p>
                                            </td>
                                            <td className='p-4 pl-8'>
                                                <p>{item.file_size}</p>
                                            </td>
                                            <td className='p-4 pl-8 text-right'>
                                                <div className='flex gap-2 justify-end'>

                                                    <Tooltip title={<span style={{ fontSize: '16px' }}>คัดลอกลิ้งก์</span>} placement="top" arrow>
                                                        <button
                                                            className='rounded-full flex justify-center items-center w-8 h-8 bg-slate-100'
                                                            onClick={() => {
                                                                if (navigator.clipboard) {
                                                                    navigator.clipboard.writeText(`${process.env.REACT_APP_CLOUD_URL}/d/${item.file_hash}`);
                                                                } else {
                                                                    const textarea = document.createElement('textarea');
                                                                    textarea.value = `${process.env.REACT_APP_CLOUD_URL}/d/${item.file_hash}`;
                                                                    document.body.appendChild(textarea);
                                                                    textarea.select();
                                                                    document.execCommand('copy');
                                                                    document.body.removeChild(textarea);
                                                                }
                                                                setAlertText({
                                                                    msg: 'คัดลอกลิ้งก์สำเร็จ',
                                                                    type: 'success'
                                                                });
                                                                setAlertOpen(true);
                                                            }}
                                                        >
                                                            <ContentCopyOutlinedIcon sx={{ fontSize: '16px' }} />
                                                        </button>
                                                    </Tooltip>

                                                </div>
                                            </td>
                                        </tr>
                                    )) : (
                                        <tr>
                                            <td colSpan={4} className='p-4 pl-8 text-center'>ไม่พบข้อมูล</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                            {loading && <div className='w-full flex justify-center p-4'><CircularProgress /></div>}
                            {!hasMore && <p className='text-center p-4 border border-t-0'>No more files to load</p>}
                        </div>
                    </div>
                }
                submitSearch={submitSearch}
                searchTextChange={search}
            />

            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={alertOpen} autoHideDuration={3000} onClose={() => setAlertOpen(false)}>
                <Alert onClose={() => setAlertOpen(false)} severity={alertText.type}>
                    {alertText.msg}
                </Alert>
            </Snackbar>
        </>
    );
}