import SingleChatPage from "./singleChat"
import MainLayout from "../layout/mainLayout"

const SingleChatClientPage = ({ socket }) => {
    return (
        <MainLayout
            //pageTitle='Chat'
            removeHeader={true}
            removePadding={true}
            disableFrom={true}
            pageContent={<SingleChatPage socket={socket} />}
        />

    );
}
export default SingleChatClientPage