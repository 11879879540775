import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ClearAllOutlinedIcon from '@mui/icons-material/ClearAllOutlined';

import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import Input from '@mui/material/Input';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import Badge from '@mui/material/Badge';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import FaceOutlinedIcon from '@mui/icons-material/FaceOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';

import NotificationList from '../components/notificationList';

export default function AppHeader({ disableFrom, submitSearch, pageTitle, searchTextChange, newMsg }) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const openUserMenu = Boolean(anchorEl);
  const handleClickUserMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };


  return (
    <>
      <div className="flex mainHeader border-b border-slate-150 px-7 py-3 justify-between items-center bg-white">
        <div className="flex items-center space-x-6">
          <button onClick={() => {
            if (localStorage.getItem('collapsed') === 'collapsed') {
              document.querySelector('.main-side-bar').classList.remove('collapsed');
              localStorage.setItem('collapsed', 'false');
            } else {
              document.querySelector('.main-side-bar').classList.add('collapsed');
              localStorage.setItem('collapsed', 'collapsed');
            }
          }}>
            <ClearAllOutlinedIcon />
          </button>

          {!disableFrom && <form ref={searchTextChange} onSubmit={submitSearch}>
            <FormControl variant="standard">
              <Input
                id="input-with-icon-adornment"
                placeholder={`ค้นหา`}
                name="top-search"
                startAdornment={
                  <InputAdornment position="start">
                    <button type="submit">
                      <SearchOutlinedIcon />
                    </button>
                  </InputAdornment>
                }
                //onChange={(e) => { searchTextChange2(e.target.value) }}
              />
            </FormControl>
          </form>}
        </div>
        <div className="flex items-center space-x-4">
          <span>
            <span>Howdy, {localStorage.getItem('name')}</span>
          </span>
          <Badge color="primary" variant="dot" invisible={newMsg} className='cursor-pointer bg-indigo-50 p-2 rounded-md' id='top-chat' onClick={() => {
            //localStorage.removeItem('newMsg');
            navigate('/chat');
          }}>
            <QuestionAnswerOutlinedIcon sx={{ color: '#3730a3' }} />
          </Badge>

          <NotificationList />

          <Avatar
            className='cursor-pointer'
            sx={{ bgcolor: '#3730a3' }}
            onClick={handleClickUserMenu}>
            {localStorage.getItem('name').charAt(0)}
          </Avatar>

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={openUserMenu}
            onClose={() => setAnchorEl(null)}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 25,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
          >
            <MenuItem onClick={() => { setAnchorEl(null); }}>
              <FaceOutlinedIcon sx={{ color: '#595c60' }} />
              <span className='ml-4'>โปรไฟล์ของฉัน</span>
            </MenuItem>
            <MenuItem onClick={() => navigate('/logout')}>
              <LogoutOutlinedIcon sx={{ color: '#595c60' }} />
              <span className='ml-4'>ออกจากระบบ</span>
            </MenuItem>
          </Menu>

        </div>
      </div>


    </>
  )
}