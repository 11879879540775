import { useState, useEffect } from 'react';
import { useInternetChecker } from '../modules/useInternetChecker';
import SideNav from "./sidenav";
import AppHeader from "./header";

const useDarkMode = localStorage.getItem('darkMode') === 'true' ? true : false;

export default function MainLayout(props) {
    const online = useInternetChecker();
    const [darkMode, setDarkMode] = useState(useDarkMode);

    useEffect(() => {
        if (darkMode) {
            localStorage.setItem('darkMode', 'true');
            document.documentElement.classList.add('dark');
        } else {
            localStorage.setItem('darkMode', 'false');
            document.documentElement.classList.remove('dark');
        }
    }, [darkMode]);

    return (
        <>
            <div className={`app-layout-modern flex flex-auto flex-col`}>
                <div className="flex flex-auto min-w-0">
                    <SideNav online={online} darkMode={darkMode} setDarkMode={setDarkMode} />
                    <div className="flex flex-col flex-auto min-h-screen min-w-0 relative w-full bg-main-bg-color justify-between max-h-screen bg-slate-50">
                        {!props.removeHeader &&
                            <AppHeader
                                pageTitle={props.pageTitle}
                                newMsg={props.newMsg ? props.newMsg : true}
                                disableFrom={props.disableFrom}
                                submitSearch={props.submitSearch}
                                searchTextChange={props.searchTextChange}
                            />
                        }
                        <div className={`relative overflow-auto mb-auto ${!props.removePadding && 'px-7 py-5'}`} id="main-content">
                            <div className='flex items-center justify-between'>
                                <div className='flex gap-4 items-center'>
                                    {props.pageTitle && <h1 className='text-xl font-bold'>{props.pageTitle}</h1>}
                                </div>
                                <div className='flex gap-2'>

                                </div>
                            </div>
                            {props.pageContent && props.pageContent}
                        </div>
                    </div>
                </div>
            </div>
            {props.pageAlert && props.pageAlert}
        </>
    )
}