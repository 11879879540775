import { useState } from 'react';
import Drawer from '@mui/material/Drawer';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Badge from '@mui/material/Badge';
import axios from 'axios';

export default function NotificationList() {

    const [openDrawer, setOpenDrawer] = useState(false);
    const [notificationList, setNotificationList] = useState(null);

    const toggleDrawer = () => {
        setOpenDrawer(false);
    }

    const handleCkickBell = () => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}/notifications`,
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            },
        }).then(function (response) {
            if (response.data.status === 200) {
                setNotificationList(response.data.data);
            }
        });
    }

    const readNotiList = (id) => {

    }

    return (
        <>

            <Badge badgeContent={0} color="primary"
                onClick={() => {
                    handleCkickBell()
                    setOpenDrawer(true)
                }}
                className='cursor-pointer bg-indigo-50 p-2 rounded-md' id='top-notification'>
                <NotificationsNoneOutlinedIcon sx={{ color: '#3730a3' }} />
            </Badge>

            <Drawer anchor={'right'} open={openDrawer} onClose={toggleDrawer}>
                <List>
                    {notificationList !== null && notificationList.length > 0 ?
                        Object.entries(notificationList).map(([k, item]) => (
                            <div key={k}>
                                <ListItem >
                                    <div className='flex gap-4 w-96 items-center rounded-md py-2'>
                                        <div className='w-1.5/12'>
                                            <div className='aspect-square bg-indigo-50 w-full rounded-full flex items-center justify-center p-2'>
                                                <NotificationsActiveOutlinedIcon sx={{ fontSize: 35, color: '#4338ca' }} />
                                            </div>
                                        </div>
                                        <div className='w-9/12'>
                                            <div className='flex justify-between'>
                                                <h4 className='text-md font-bold text-gray-800'>{item.title}</h4>
                                                {item.time && <p className='text-sm'>{item.time}</p>}
                                            </div>

                                            <p className='text-gray-600 text-sm'>
                                                {item.msg && item.msg}
                                            </p>

                                        </div>
                                        <div className='w-1.5/12'>
                                            {item.hide &&
                                                <button onClick={(e) => {
                                                    readNotiList(item.hide)
                                                    //handleCkickBell()
                                                }}>
                                                    <CloseOutlinedIcon />
                                                </button>
                                            }
                                        </div>
                                    </div>
                                </ListItem>
                                <Divider />
                            </div>
                        ))
                        :
                        <ListItem>
                            <div className='w-96'>
                                <div className='text-center'>
                                    ยังไม่มีการแจ้งเตือน
                                </div>
                            </div>
                        </ListItem>
                    }
                </List>
            </Drawer>
        </>
    );
}