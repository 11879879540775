import { useState, useEffect, useRef, useCallback, forwardRef } from 'react';
import { Link } from 'react-router-dom';
import MainLayout from "../layout/mainLayout";
import Pagination from '@mui/material/Pagination';
import PageSKT from '../components/tableSkt';
import axios from 'axios';
import Tooltip from '@mui/material/Tooltip';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import ArrowRightAltOutlinedIcon from '@mui/icons-material/ArrowRightAltOutlined';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
//import DialogTitle from '@mui/material/DialogTitle';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function OrderFromWeb() {

    const page = useRef(1);
    const tableRef = useRef(null);
    const search = useRef(null);
    const [notfound, setNotfound] = useState(false);

    const [data, setData] = useState([]);
    const [paginate, setPaginate] = useState({
        current_page: 1,
        per_page: 20,
        total_pages: 41,
        total_orders: 816
    });

    const [alert, setAlert] = useState({
        open: false,
        msg: '',
        type: 'error'
    });

    const fetchData = useCallback(async (search = '') => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_SHOP_API_URL}/app-admin/orders/${page.current}?search=${search}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            setData(res.data.data);
            setPaginate(res.data.pagination);
            if (res.data.data.length === 0) {
                setNotfound(true);
            }
            tableRef.current?.scrollIntoView({ behavior: 'smooth' });
        } catch (error) {
            console.log(error);
        }
    }, []);

    useEffect(() => {
        fetchData();
        document.title = `คำสั่งซื้อ - ${process.env.REACT_APP_SITE_NAME}`;
    }, [fetchData]);

    const orderStatusNametoColor = (status) => {
        switch (status) {
            case 'completed':
                return '#4caf50';
            case 'pending':
                return '#ff9800';
            case 'processing':
                return '#2196f3';
            case 'cancelled':
                return '#f44336';
            default:
                return '#4caf50';
        }
    }

    const triggerUpdateOrderStatus = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_SHOP_API_URL}/cronjob/update-order-status`);
            if (res.status === 200) {
                page.current = 1;
                fetchData();
                setAlert({
                    open: true,
                    msg: 'อัพเดทสถานะสำเร็จ',
                    type: 'success'
                });
            }
            tableRef.current?.scrollIntoView({ behavior: 'smooth' });
        } catch (error) {
            console.log(error);
        }
    }

    const submitSearch = (e) => {
        e.preventDefault();
        const inputValue = search.current.elements["top-search"].value;
        if (inputValue.trim() === "") {
            return;
        }
        page.current = 1;
        fetchData(inputValue);
    }

    const [selected, setSelected] = useState({
        id: null,
        user_email: "",
        user_id: 5076,
        order_number: "",
        order_date: Date.now(),
        order_status: "cancelled",
        order_total: "00.00",
        order_coupon: null,
        order_discount: "0.00",
        order_total_after_discount: "00.00",
        order_payment_method: "tra",
        order_payment_status: "cancelled",
        order_update_at: Date.now(),
        items: []
    });

    const resetSelected = () => {
        setSelected({
            id: null,
            user_email: "",
            user_id: 5076,
            order_number: "",
            order_date: Date.now(),
            order_status: "cancelled",
            order_total: "00.00",
            order_coupon: null,
            order_discount: "0.00",
            order_total_after_discount: "00.00",
            order_payment_method: "tra",
            order_payment_status: "cancelled",
            order_update_at: Date.now(),
            items: []
        });
    }


    const [open, setOpen] = useState(false);

    const putUpdateOrderDetail = async () => {
        try {
            const res = await axios.put(`${process.env.REACT_APP_SHOP_API_URL}/app-admin/order/${selected?.id}`, selected, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            if (res.status === 200) {
                fetchData();
                setAlert({
                    open: true,
                    msg: 'อัพเดทสถานะสำเร็จ',
                    type: 'success'
                });
                setOpen(false);
                resetSelected();
            }
            tableRef.current?.scrollIntoView({ behavior: 'smooth' });
        } catch (error) {
            console.log(error);
            setAlert({
                open: true,
                msg: 'อัพเดทสถานะไม่สำเร็จ',
                type: 'error'
            });
        }
    }

    return (
        <>
            <MainLayout
                disableFrom={false}
                removePadding={true}
                pageContent={
                    <div id="real-main" className='flex flex-col justify-between px-6' ref={tableRef} style={{
                        minHeight: 'calc(100vh - 34px - 2rem)'
                    }}>
                        <div>
                            <div className='flex justify-between items-center my-4'>
                                <div className='flex justify-between items-center gap-4'>
                                    <h1 className='font-bold text-xl '>คำสั่งซื้อจากเว็บไซต์</h1>
                                    <Link to='/orders' className='bg-slate-100 rounded-full px-4 py-2 text-sm flex justify-center items-center gap-2'>
                                        <span>คำสั่งซื้อจากแชท</span> <ArrowRightAltOutlinedIcon sx={{ fontSize: '16px' }} />
                                    </Link>
                                </div>

                                <div className='flex gap-4'>

                                    <Button variant="contained" color="primary" onClick={() => triggerUpdateOrderStatus()}>
                                        อัพเดทสถานะ
                                    </Button>
                                </div>
                            </div>

                            <div className='table-responsive mt-2 bg-white'>
                                <table className="table-auto w-full border-collapse border border-slate-150">
                                    <thead>
                                        <tr>
                                            <th className='text-left border-b border-slate-150 p-4 pl-8 pt-3 pb-3 font-bold text-noto'>เลขที่</th>
                                            <th className='text-left border-b border-slate-150 p-4 pl-8 pt-3 pb-3 font-bold text-noto'>ลูกค้า</th>
                                            <th className='text-left border-b border-slate-150 p-4 pl-8 pt-3 pb-3 font-bold text-noto'>วันที่</th>
                                            <th className='text-center border-b border-slate-150 p-4 pl-8 pt-3 pb-3 font-bold text-noto'>สถานะ</th>
                                            <th className='text-center border-b border-slate-150 p-4 pl-8 pt-3 pb-3 font-bold text-noto'>ช่องทางชำระเงิน</th>
                                            <th className='text-right border-b border-slate-150 p-4 pl-8 pt-3 pb-3 font-bold text-noto'>รวม</th>
                                            <th className='text-right border-b border-slate-150 p-4 pl-8 pr-6 pt-3 pb-3 font-bold text-noto'></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.length > 0 && !notfound ? Object.entries(data).map(([k, item]) => (
                                            <tr className='align-center border-b border-b-slate-150 hover:bg-slate-50' key={k}>
                                                <td className='p-4 py-1 pl-8 text-xs'>
                                                    {item.order_number}
                                                </td>
                                                <td className='p-4 pl-8 flex gap-4 items-center'>

                                                    <h4 className='text-xs'>{item.user_email}</h4>

                                                </td>
                                                <td className='p-4 pl-8 text-xs'>
                                                    <p>{
                                                        new Date(item.order_date).toLocaleString('th-TH', {
                                                            year: 'numeric',
                                                            month: 'long',
                                                            day: 'numeric',
                                                            hour: 'numeric',
                                                            minute: 'numeric'
                                                        })
                                                    }</p>
                                                </td>
                                                <td className='p-4 pl-8 text-center'>
                                                    <span className='px-2 py-1 uppercase rounded-full text-xs text-white' style={{ backgroundColor: orderStatusNametoColor(item.order_status) }}>
                                                        {item.order_status}
                                                    </span>
                                                </td>
                                                <td className='p-4 pl-8 text-center'>
                                                    <span className='px-2 py-1 rounded-full text-xs'>
                                                        {item.order_payment_method === 'tra' ? 'โอนเงิน' : item.order_payment_method === 'cod' ? 'เก็บเงินปลายทาง' : item.order_payment_method === 'paypal' ? 'Paypal' : item.order_payment_method === 'cre' ? 'บัตรเครดิต' : item.order_payment_method}
                                                    </span>
                                                </td>
                                                <td className='p-4 pl-8 text-right text-xs'>
                                                    <span>{item.order_total_after_discount} <small>฿</small></span>
                                                </td>
                                                <td className='p-4 pl-8 text-right'>
                                                    <div className='flex gap-2 justify-end'>
                                                        <Tooltip title={<span style={{ fontSize: '16px' }}>แก้ไขสินค้า</span>} placement="top" arrow>
                                                            <button
                                                                className='rounded-full flex justify-center items-center w-8 h-8 bg-slate-100'
                                                                onClick={() => {
                                                                    setSelected(item);
                                                                    setOpen(true);
                                                                    //console.log(item);
                                                                }}
                                                            >
                                                                <EditOutlinedIcon sx={{ fontSize: '16px' }} />
                                                            </button>
                                                        </Tooltip>

                                                    </div>
                                                </td>
                                            </tr>
                                        )) :
                                            notfound ? <tr><td colSpan={7}><p className='p-4 text-center'>ไม่พบข้อมูล</p></td></tr> :

                                                <PageSKT tr={13} td={7} />
                                        }
                                    </tbody>
                                </table>

                            </div>
                        </div>

                        <div className='flex p-4 justify-between items-center sticky bottom-0 bg-slate-50'>
                            <div className='text-noto'>
                                หน้าที่ {page.current + 1} จาก {paginate.total_pages}
                            </div>
                            <Pagination
                                count={paginate.total_pages}
                                page={page.current}
                                onChange={(e, v) => {
                                    setData([]);
                                    page.current = v;
                                    fetchData();
                                }}
                            />
                        </div>
                    </div>
                }
                submitSearch={submitSearch}
                searchTextChange={search}
            />

            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={3000} open={alert.open} onClose={() => setAlert({ ...alert, open: false })}>
                <Alert onClose={() => setAlert({ ...alert, open: false })} severity={alert.type} sx={{ width: '100%' }}>
                    {alert.msg}
                </Alert>
            </Snackbar>

            <Dialog
                open={open}
                onClose={() => { setOpen(false); resetSelected(); }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth='md'
                fullWidth
            >
                <div className='p-6 border-b flex justify-between items-center'>
                    <h4 className='text-noto text-xl'>
                        <span className='font-bold'>คำสั่งซื้อ {selected?.order_number} </span><br />
                        <small>{
                            new Date(selected?.order_date).toLocaleString('th-TH', {
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric',
                                hour: 'numeric',
                                minute: 'numeric'
                            })
                        }</small>
                    </h4>
                    <div style={{ minWidth: '200px' }}>
                        <FormControl variant="standard" fullWidth>
                            <InputLabel>สถานะคำสั่งซื้อ</InputLabel>
                            <Select
                                value={selected?.order_status}
                                onChange={(evt) => {
                                    setSelected({ ...selected, order_status: evt.target.value })
                                }}
                                className='text-noto'
                                MenuProps={{
                                    MenuListProps: {
                                        disablePadding: true,
                                    },
                                }}
                            >
                                <MenuItem value={"completed"}>Completed</MenuItem>
                                <MenuItem value={"pending"} >Pending</MenuItem>
                                <MenuItem value={"cancelled"} >Cancelled</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <DialogContent>
                    <div className='flex flex-row gap-4 justify-between mb-8'>

                        <div className='flex gap-4 items-center w-full'>
                            <FormControl variant="filled" fullWidth>
                                <InputLabel id="demo-simple-select-filled-label">การชำระเงิน</InputLabel>
                                <Select
                                    value={selected?.order_payment_method}
                                    onChange={(evt) => {
                                        setSelected({ ...selected, order_payment_method: evt.target.value })
                                    }}
                                    className='text-noto'
                                >
                                    <MenuItem value={"tra"}>Transfer</MenuItem>
                                    <MenuItem value={"cre"}>Credit Card</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div className='flex gap-4 items-center w-full'>
                            <FormControl variant="filled" fullWidth>
                                <InputLabel id="demo-simple-select-filled-label">สถานะการชำระเงิน</InputLabel>
                                <Select
                                    labelId="demo-simple-select-filled-label"
                                    id="demo-simple-select-filled"
                                    value={selected?.order_payment_status}
                                    onChange={(evt) => {
                                        //Set state
                                        setSelected({ ...selected, order_payment_status: evt.target.value })
                                    }}
                                    className='text-noto'
                                >
                                    <MenuItem value={"paid"}>Paid</MenuItem>
                                    <MenuItem value={"pending"}>Pending</MenuItem>
                                    <MenuItem value={"cancelled"}>Cancelled</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </div>

                    {/* Order description */}
                    <div className='mt-4'>
                        <h4 className='text-noto font-bold'>รายการสินค้า</h4>
                        <div className='table-responsive mt-2 bg-white'>
                            <table className="table-auto w-full border-collapse border border-slate-150">
                                <thead>
                                    <tr>
                                        <th className='text-left border-b border-slate-150 p-4 pt-3 pb-3 font-bold text-noto'>สินค้า</th>
                                        <th className='border-b border-slate-150 p-4 pl-8 pt-3 pb-3 font-bold text-noto text-center'>จำนวน</th>
                                        <th className='text-right border-b border-slate-150 p-4 pl-8 pt-3 pb-3 font-bold text-noto'>ราคา</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {selected && selected.items.map((item, k) => (
                                        <tr className='align-center border-b border-b-slate-150' key={k}>
                                            <td className='p-4 py-1 text-xs'>
                                                <Link
                                                    to={`/products/${item.product?.slug}`}
                                                    className='underline'
                                                >
                                                    {item.product?.name}
                                                </Link>
                                            </td>
                                            <td className='p-4 pl-8 text-xs text-center'>
                                                <p>{item.qty}</p>
                                            </td>
                                            <td className='p-4 pl-8 text-right text-xs'>
                                                <span>{item.price} <small>฿</small></span>
                                            </td>

                                        </tr>
                                    ))}
                                    <tr className='align-center border-b border-b-slate-150'>
                                        <td className='p-4 py-1 text-xs'>
                                            <span>ส่วนลด</span>
                                        </td>
                                        <td className='p-4 pl-8 text-xs text-center'>

                                        </td>
                                        <td className='p-4 pl-8 text-right text-xs'>
                                            <span>{selected?.order_discount} <small>฿</small></span>
                                        </td>
                                    </tr>
                                    <tr className='align-center border-b border-b-slate-150'>
                                        <td className='p-4 py-1 text-xs'>
                                            <span>รวมทั้งหมด</span>
                                        </td>
                                        <td className='p-4 pl-8 text-xs text-center'>

                                        </td>
                                        <td className='p-4 pl-8 text-right text-xs'>
                                            <span>{selected?.order_total_after_discount} <small>฿</small></span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                    </div>


                    <div className='mt-4'>
                        <h4 className='text-noto font-bold'>รายการดาวน์โหลด</h4>
                        <div className='table-responsive mt-2 bg-white'>
                            <table className="table-auto w-full border-collapse border border-slate-150">
                                <thead>
                                    <tr>
                                        <th className='text-left border-b border-slate-150 p-4 pt-3 pb-3 font-bold text-noto'>สินค้า</th>
                                        <th className='text-right border-b border-slate-150 p-4 pl-8 pt-3 pb-3 font-bold text-noto'>ดาวน์โหลด</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {selected && selected.items.map((item, k) => (
                                        item.product?.downloads?.length > 0 && item.product?.downloads.map((download, kx) => (

                                            <tr className='align-center border-b border-b-slate-150' key={kx}>
                                                <td className='p-4 py-1 text-xs'>
                                                    {item.product?.name}
                                                </td>
                                                <td className='p-4 pl-8 text-right text-xs'>
                                                    <a
                                                        href={download.file}
                                                        className='p-2 inline-flex gap-1 rounded-full justify-end underline items-end'
                                                        target='_blank'
                                                        rel='noreferrer'
                                                    >
                                                        <CloudDownloadOutlinedIcon sx={{ fontSize: '16px' }} />
                                                        {download.name}
                                                    </a>
                                                </td>
                                            </tr>
                                        ))
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>


                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setOpen(false); resetSelected(); }}>ยกเลิก</Button>
                    <Button onClick={() => putUpdateOrderDetail()} autoFocus>บันทึก</Button>
                </DialogActions>
            </Dialog>

        </>
    );
}