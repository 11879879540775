import { useState, useEffect } from 'react';
import axios from 'axios';
import MainLayout from "../layout/mainLayout";
import Card from '@mui/material/Card';
//import CardActions from '@mui/material/CardActions';
//import Skeleton from '@mui/material/Skeleton';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar, Line } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export default function Dashboard() {

    const [totalSalesData, setTotalSalesData] = useState(null);

    useEffect(() => {

        document.title = `แดชบอร์ด - ${process.env.REACT_APP_SITE_NAME}`;

        axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}/report/dashboard`,
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            },
        }).then(function (response) {
            setTotalSalesData(response.data);
        });

    }, []);

    function addCommas(num) {
        const n = parseFloat(num);
        if (isNaN(n)) {
            return "Invalid input";
        }

        return n.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
    }


    const CreateServerUsageCard = () => {
        return (
            <>
                <div className='grid grid-cols-4 gap-5 mt-4'>
                    <Card className='custom-card-bg relative' sx={{ backgroundColor: '#3730a3', padding: '0.5em' }}>
                        <CardContent>
                            <div className='flex'>
                                <div className='w-6/12'>
                                    <Typography sx={{ fontSize: 14, color: '#fff' }} gutterBottom>
                                        ยอดขายวันนี้ {totalSalesData ? totalSalesData.totalSales.count : 0} รายการ
                                    </Typography>
                                    <Typography variant="h5" component="div" sx={{ color: '#fff' }} >
                                        {totalSalesData ? totalSalesData.totalSales.total : 0.00} บาท
                                    </Typography>
                                    <Button sx={{ marginTop: '0.75rem', color: '#fff', backgroundColor: 'rgb(255 255 255 / 13%)' }} size="small" >จัดการ</Button>
                                </div>
                                <div className='w-6/12'>
                                    {totalSalesData && <Line
                                        options={{
                                            responsive: true,
                                            elements: {
                                                point: {
                                                    radius: 1
                                                }
                                            },
                                            plugins: {
                                                legend: {
                                                    display: false,
                                                },
                                            },
                                            scales: {
                                                x: {
                                                    ticks: {
                                                        display: false,
                                                    },
                                                    grid: {
                                                        drawBorder: false,
                                                        display: false,
                                                    },
                                                    border: {
                                                        display: false
                                                    }
                                                },
                                                y: {
                                                    ticks: {
                                                        display: false,
                                                        beginAtZero: true,
                                                    },
                                                    grid: {
                                                        drawBorder: false,
                                                        display: false
                                                    },
                                                    border: {
                                                        display: false
                                                    }
                                                },
                                            }
                                        }}
                                        data={{
                                            labels: totalSalesData.sevendaySales.map((item) => item.date),
                                            datasets: [
                                                {
                                                    label: "ยอดขาย",
                                                    backgroundColor: "#facc15",
                                                    borderColor: "#facc15",
                                                    pointBackgroundColor: "#facc15",
                                                    pointBorderColor: "#facc15",
                                                    data: totalSalesData.sevendaySales.map((item) => parseFloat(item.total_sales)),
                                                },
                                            ],
                                        }}
                                    />}
                                </div>
                            </div>
                        </CardContent>
                    </Card>

                    <Card className='custom-card-bg-2 relative' sx={{ backgroundColor: '#facc15', padding: '0.5em' }}>
                        <CardContent>
                            <div className='flex'>
                                <div className='w-6/12'>
                                    <Typography sx={{ fontSize: 14 }} color="#000" gutterBottom>
                                        ลูกค้าใหม่วันนี้
                                    </Typography>
                                    <Typography variant="h5" component="div">
                                        {totalSalesData ? totalSalesData.totalCustomer : 0} ราย
                                    </Typography>
                                    <Button sx={{ marginTop: '0.75rem', backgroundColor: 'rgb(255 217 66)' }} size="small" >จัดการ</Button>
                                </div>
                                <div className='w-6/12'>
                                    {totalSalesData && <Line
                                        options={{
                                            responsive: true,
                                            elements: {
                                                point: {
                                                    radius: 1
                                                }
                                            },
                                            plugins: {
                                                legend: {
                                                    display: false,
                                                },
                                            },
                                            scales: {
                                                x: {
                                                    ticks: {
                                                        display: false,
                                                    },
                                                    grid: {
                                                        drawBorder: false,
                                                        display: false,
                                                    },
                                                    border: {
                                                        display: false
                                                    }
                                                },
                                                y: {
                                                    ticks: {
                                                        display: false,
                                                        beginAtZero: true,
                                                    },
                                                    grid: {
                                                        drawBorder: false,
                                                        display: false
                                                    },
                                                    border: {
                                                        display: false
                                                    }
                                                },
                                            }
                                        }}
                                        data={{
                                            labels: totalSalesData.sevendayCustomer.map((item) => item.date),
                                            datasets: [
                                                {
                                                    label: "ลูกค้าใหม่",
                                                    backgroundColor: "#3730a3",
                                                    borderColor: "#3730a3",
                                                    pointBackgroundColor: "#3730a3",
                                                    pointBorderColor: "#3730a3",
                                                    data: totalSalesData.sevendayCustomer.map((item) => parseFloat(item.total_sales)),
                                                },
                                            ],
                                        }}
                                    />}
                                </div>
                            </div>
                        </CardContent>
                    </Card>

                    <Card className='relative' sx={{ padding: '0.5em' }}>
                        <CardContent>
                            <div className='flex justify-between items-center'>
                                <div className='w-6/12'>
                                    <Typography sx={{ fontSize: 14 }} color="#000" gutterBottom>
                                        ยอดขายเดือนนี้ {totalSalesData ? totalSalesData.monthlySales.count : 0} รายการ
                                    </Typography>
                                    <Typography variant="h5" component="div">
                                        {totalSalesData ? addCommas(totalSalesData.monthlySales.total) : 0} บาท
                                    </Typography>
                                    <Button sx={{ marginTop: '0.75rem', backgroundColor: 'rgba(81 75 175 / 20%)' }} size="small" >จัดการ</Button>
                                </div>
                                <div className='w-6/12 font-black text-noto text-4xl text-indigo-700'>
                                    <div className='flex flex-wrap justify-center items-baseline'>
                                        {totalSalesData ? totalSalesData.saleDiff : '0'}<span className='text-sm ml-2'>%</span>
                                        <div className='text-sm font-normal text-gray-500 text-center w-full'>จากเดือนที่แล้ว</div>
                                    </div>
                                </div>
                            </div>
                        </CardContent>
                    </Card>

                    <Card className='relative' sx={{ padding: '0.5em' }}>
                        <CardContent>
                            <div className='flex justify-between items-center'>
                                <div className='w-6/12'>
                                    <Typography sx={{ fontSize: 14 }} color="#000" gutterBottom>
                                        ลูกค้าใหม่เดือนนี้
                                    </Typography>
                                    <Typography variant="h5" component="div">
                                        {totalSalesData ? totalSalesData.customerDiff.count : 0} ราย
                                    </Typography>
                                    <Button sx={{ marginTop: '0.75rem', backgroundColor: 'rgba(81 75 175 / 20%)' }} size="small" >จัดการ</Button>
                                </div>
                                <div className='w-6/12 text-right font-black text-noto text-4xl text-indigo-700'>
                                    <div className='flex flex-wrap justify-center items-baseline'>
                                        {totalSalesData ? totalSalesData.customerDiff.diff : '0'} <span className='text-sm ml-2'>%</span>
                                        <div className='text-sm font-normal text-gray-500 text-center w-full'>จากเดือนที่แล้ว</div>
                                    </div>
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                </div>



                <div className='mt-5 grid grid-cols-2 gap-5'>
                    <Card>
                        <CardContent>
                            <Bar
                                //height={235}
                                options={{
                                    responsive: true,
                                    plugins: {
                                        legend: {
                                            position: 'top',
                                            labels: {
                                                font: {
                                                    family: 'Noto Sans Thai',
                                                }
                                            }
                                        },
                                    },
                                }}
                                data={{
                                    labels: totalSalesData && totalSalesData.sevendaySales.map((item) => item.date),
                                    datasets: [

                                        {
                                            label: "ยอดขาย",
                                            backgroundColor: "#3730a3",
                                            borderColor: "#3730a3",
                                            pointBackgroundColor: "rgba(151, 187, 205, 1)",
                                            pointBorderColor: "#fff",
                                            data: totalSalesData && totalSalesData.sevendaySales.map((item) => parseFloat(item.total_sales)),
                                        },
                                    ],
                                }}
                            />
                        </CardContent>
                    </Card>

                    <Card>
                        <CardContent>
                            <Bar
                                //height={235}
                                options={{
                                    responsive: true,
                                    plugins: {
                                        legend: {
                                            position: 'top',
                                            labels: {
                                                font: {
                                                    family: 'Noto Sans Thai',
                                                }
                                            }
                                        },
                                    },
                                }}
                                data={{
                                    labels: totalSalesData && totalSalesData.sevendaySales.map((item) => item.date),
                                    datasets: [
                                        {
                                            label: "ลูกค้าทักแชท",
                                            backgroundColor: "#facc15",
                                            borderColor: "#facc15",
                                            pointBackgroundColor: "rgba(220, 220, 220, 1)",
                                            pointBorderColor: "#fff",
                                            data: totalSalesData && totalSalesData.totalMsg.map((item) => parseFloat(item.Customers)),
                                        },

                                    ],
                                }}
                            />
                        </CardContent>
                    </Card>
                </div>
                <div className='mt-5 grid grid-cols-2 gap-5'>
                    <Card>
                        <CardContent>
                            <Bar
                                //height={235}
                                options={{
                                    indexAxis: 'y',
                                    responsive: true,
                                    plugins: {
                                        legend: {
                                            position: 'top',
                                            labels: {
                                                font: {
                                                    family: 'Noto Sans Thai',
                                                }
                                            }
                                        },
                                    },
                                    scales: {
                                        x: {
                                            ticks: {
                                                font: {
                                                    family: 'Noto Sans Thai',
                                                },
                                            },
                                        },
                                        y: {
                                            ticks: {
                                                font: {
                                                    family: 'Noto Sans Thai',
                                                },
                                            },
                                        },
                                    },
                                }}
                                data={{
                                    labels: totalSalesData && totalSalesData.productBest.map((item) => item.name),
                                    datasets: [
                                        {
                                            label: "ยอดขาย",
                                            backgroundColor: "#facc15",
                                            borderColor: "#facc15",
                                            pointBackgroundColor: "rgba(220, 220, 220, 1)",
                                            pointBorderColor: "#fff",
                                            data: totalSalesData && totalSalesData.productBest.map((item) => parseFloat(item.qty)),
                                        },

                                    ],
                                }}
                            />
                        </CardContent>
                    </Card>
                    <Card>
                        <CardContent>
                            <Bar
                                //height={235}
                                options={{
                                    indexAxis: 'y',
                                    responsive: true,
                                    plugins: {
                                        legend: {
                                            position: 'top',
                                            labels: {
                                                font: {
                                                    family: 'Noto Sans Thai',
                                                }
                                            }
                                        },
                                    },
                                    scales: {
                                        x: {
                                            ticks: {
                                                font: {
                                                    family: 'Noto Sans Thai',
                                                },
                                            },
                                        },
                                        y: {
                                            ticks: {
                                                font: {
                                                    family: 'Noto Sans Thai',
                                                },
                                            },
                                        },
                                    },
                                }}
                                data={{
                                    labels: totalSalesData && totalSalesData.productBest.map((item) => item.name),
                                    datasets: [

                                        {
                                            label: "รายได้",
                                            backgroundColor: "#3730a3",
                                            borderColor: "#3730a3",
                                            pointBackgroundColor: "rgba(151, 187, 205, 1)",
                                            pointBorderColor: "#fff",
                                            data: totalSalesData && totalSalesData.productBest.map((item) => parseFloat(item.val)),
                                        },
                                    ],
                                }}
                            />
                        </CardContent>
                    </Card>
                </div>





            </>
        )
    }

    return (
        <MainLayout
            //pageTitle='แดชบอร์ด'
            disableFrom={true}
            pageContent={
                <CreateServerUsageCard />
            }
        />
    );
}